import { gql, useMutation } from "@apollo/client";
import { Trans } from "@lingui/macro";

import './ForcePodcastUpdate.css';

const FORCE_PODCAST_UPDATE = gql`
    mutation PodcastForceUpdate($podcast_id: Int!) {
        podcastForceUpdate(podcast_id: $podcast_id)
    }
`;

interface ForcePodcastUpdateProps {
    podcastId: number
}

function ForcePodcastUpdate({ podcastId } : ForcePodcastUpdateProps) {
    const [forcePodcastUpdate, { data, loading, error }] = useMutation(FORCE_PODCAST_UPDATE, {
        variables: {
            podcast_id: podcastId
        },
    });

    let content = <a onClick={() => forcePodcastUpdate()}><Trans>Очистить кэш</Trans></a>;
    if (loading) content = <p>...</p>;
    if (data) {
        if (data.podcastForceUpdate) {
            content = <p><Trans>Запрос отправлен, кэш будет очищен в течение 10 минут</Trans></p>
        } else {
            content = <p><Trans>Чистить кэш можно не чаще, чем раз в 30 минут</Trans></p>
        }
    }
    if (error) content = <p><Trans>Что то пошло не так, не удалось очистить кэш</Trans></p>;

    return <div className="clear-cache">{content}</div>
}

export default ForcePodcastUpdate;
