import { Outlet } from "react-router-dom";
import { i18n } from '@lingui/core'

import { en, ru, es, fr, de, uk, be, kk } from 'make-plural/plurals';
import {messages as ruMessages} from '../locales/ru/messages'
import {messages as enMessages} from '../locales/en/messages'
import {messages as esMessages} from '../locales/es/messages'
import {messages as frMessages} from '../locales/fr/messages'
import {messages as deMessages} from '../locales/de/messages'
import {messages as ukMessages} from '../locales/uk/messages'
import {messages as beMessages} from '../locales/be/messages'
import {messages as kkMessages} from '../locales/kk/messages'
import {useReactiveVar} from "@apollo/client";
import {selectedLanguage} from "./cache";
import {useEffect} from "react";

i18n.loadLocaleData('ru', { plurals: ru });
i18n.loadLocaleData('en', { plurals: en });
i18n.loadLocaleData('es', { plurals: es });
i18n.loadLocaleData('fr', { plurals: fr });
i18n.loadLocaleData('de', { plurals: de });
i18n.loadLocaleData('uk', { plurals: uk });
i18n.loadLocaleData('be', { plurals: be });
i18n.loadLocaleData('kk', { plurals: kk });

i18n.load( {
    ru: ruMessages,
    en: enMessages,
    es: esMessages,
    fr: frMessages,
    de: deMessages,
    uk: ukMessages,
    be: beMessages,
    kk: kkMessages,
});


export default function I18nLoader({ locale } : {locale: string | undefined}) {
    // const { locale } = useParams<{locale: string}>();
    const language = useReactiveVar(selectedLanguage);
    const languageSelected = locale || language;
    useEffect(() => {
        i18n.activate(languageSelected);
    }, [languageSelected]);
    // console.log("Language:", languageSelected);

    return <>
        <Outlet />
    </>;
}
