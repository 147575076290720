import { useState, useEffect } from "react";
import { ColorPicker, useColor } from "react-color-palette";
import { Trans } from "@lingui/macro";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

// import closeModalIcon from './../../../assets/img/modal-close.svg';
// import copyIcon from './../../../assets/img/copy.svg';
// import colorCheckmarkIcon from './../../../assets/img/color-checkmark.svg';
// import customColorBg from './../../../assets/img/custom-color-bg.svg';


interface ColorSettingsProps {
    show: boolean
    color: string
    changeColor: any
    defaultColor: string
}

export default function ColorSettings(props: ColorSettingsProps) {
    const [colorCustom, setColorCustom] = useState(false); // if color is custom
    const [colorCustomOpen, setColorCustomOpen] = useState(false); // if color modal is opened
    const colorsStandart = [
        { value: props.defaultColor, invert: false },
        { value: '#F3F3F3', invert: true }
    ];

    const colorsPalette = [
        { value: '#73BFF9', invert: false },
        { value: '#98FAEA', invert: false },
        { value: '#A4F76A', invert: false },
        { value: '#FDF171', invert: false },
        { value: '#F19B91', invert: false },
        { value: '#F19AC8', invert: false },
        { value: '#000000', invert: false },
        { value: '#929292', invert: false },
        { value: '#FFFFFF', invert: true }
    ];
    const buttonsColors = [
        { value: '#1E8BE4', invert: false },
        { value: '#000000', invert: false },
        { value: '#FFFFFF', invert: true }
    ];

    const setCustomColor = () => {
        setColorCustom(true);
        setColorCustomOpen(true);
    }
    const closeCustomColor = () => {
        setColorCustomOpen(false);
    }
    // @ts-ignore
    const setColor = (event: MouseEvent<HTMLElement>) => {
        setColorCustom(false);
        props.changeColor(event);
    }

    return (
        <div className={`appearence-item-settings color ${props.show ? 'show' : ''}`}>
            {/* <div className="appearence-label-title"> Фон </div> */}
            <div className="appearence-color-background-container background">
                <div className="appearence-color-background-standart">
                    <div className="color-label"><Trans>Стандартный</Trans></div>
                    <div className="color-list">
                        {
                            colorsStandart.map(el =>
                                <div className="color-option" onClick={setColor} data-value={el.value} key={el.value} style={{ backgroundColor: el.value }}>
                                    <img src="/assets/img/color-checkmark.svg" data-value={el.value} className={`color-checkmark ${props.color === el.value ? 'show' : ''} ${el.invert ? 'invert' : ''}`} />
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className="appearence-color-background-palette">
                    <div className="color-label"><Trans>Палитра</Trans></div>
                    <div className="color-list">
                        {
                            colorsPalette.map(el =>
                                <div className="color-option" onClick={setColor} data-value={el.value} key={el.value} style={{ backgroundColor: el.value }}>
                                    <img src="/assets/img/color-checkmark.svg" data-value={el.value} className={`color-checkmark ${props.color === el.value ? 'show' : ''} ${el.invert ? 'invert' : ''}`} />
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className="appearence-color-background-custom">
                    <div className="color-label"><Trans>Свой цвет</Trans></div>
                    <div className="color-list">
                        <div className="color-option color-custom" onClick={setCustomColor} style={{ background: '/assets/img/custom-color-bg.svg' }}>
                            <img src="/assets/img/color-checkmark.svg" className={`color-checkmark invert ${colorCustom ? 'show' : ''}`} />
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="appearence-label-title"> Кнопки </div>
            <div className="appearence-color-background-container">
                <div className="appearence-color-background-standart">
                    <div className="color-list">
                        {
                            buttonsColors.map(el =>
                                <div className="color-option" onClick={props.changeButtonsColor} data-value={el.value} key={el.value} style={{ backgroundColor: el.value }}>
                                    <img src={colorCheckmarkIcon} data-value={el.value} className={`color-checkmark ${props.buttonsColor === el.value ? 'show' : ''} ${el.invert ? 'invert' : ''}`} />
                                </div>
                            )
                        }
                    </div>
                </div>
            </div> */}
            <ColorPaletteModal color={props.color} changeColor={props.changeColor} handleClose={closeCustomColor} open={colorCustomOpen} />
        </div>
    )
}

export function ColorPaletteModal(props: any) {
    const [color, setColor] = useColor("hex", props.color);
    useEffect(() => {
      props.changeColor({target: {dataset: {value: color.hex}}});
    }, [color]);
  
    const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 236,
      height: '384',
      overflow: 'none',
      borderRadius: '8px',
      bgcolor: '#FFFFFF',
      padding: 0
    };
  
    return (
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="header color-picker-header">
            <div className="title color-picker-title"><Trans>Свой цвет</Trans></div>
            <div className="modal-close color-picker-close" onClick={props.handleClose}><img src="/assets/img/modal-close.svg" alt="close"/></div>
          </div>
          <ColorPicker width={236} height={208} color={color} onChange={setColor} hideHSV hideRGB />
          <button className="copy-btn color-btn" onClick={props.handleClose}><img src="/assets/img/copy.svg" alt="" /><Trans>Выбрать цвет</Trans></button>
        </Box>
      </Modal>
    )
  }
