import React, { useState } from "react";
import {Slider} from "@mui/material";

import useAudioTime from "../../hooks/useAudioTime";
import usePlayer from "../../hooks/usePlayer";

import styles from "./TrackProgress.module.css";

interface TrackProgressProps {
    isLoading: boolean
}


export default function TrackProgress({isLoading} : TrackProgressProps) {
    const { duration, seek, isReady } = usePlayer();
    const position = useAudioTime();
    const [isMoving, _setIsMoving] = useState<boolean>(false);
    const [movingPosition, _setMovingPosition] = useState<number | null>(null);

    const isMovingRef = React.useRef(isMoving);
    const setIsMoving = (data: boolean) => {
        isMovingRef.current = data;
        _setIsMoving(data);
    };

    const movingPositionRef = React.useRef(movingPosition);
    const setMovingPosition = (data: number | null) => {
        movingPositionRef.current = data;
        _setMovingPosition(data);
    };

    const handleMove = (event: any, pos: number | number[]) => {
        const value = Array.isArray(pos) ? pos[0] : pos;
        setIsMoving(true);
        setMovingPosition(value);
    }

    const handleChange = (event: any, newValue: number | number[]) => {
        const value = Array.isArray(newValue) ? newValue[0] : newValue;
        seek(value);
    };

    const handleStart = () => {
        setIsMoving(true);
    }

    return <div className={`${styles.progressContainer} ${isReady ? styles.ready : ''}`}>
        {isReady ? <Slider
          size="small"
          value={movingPosition !== null ? movingPosition : position}
          aria-label="PC.ST player"
          min={0}
          max={duration}
          onChange={handleMove}
          onChangeCommitted={handleChange}
          onDragStart={handleStart}
          onTouchStart={handleStart}
        /> : <div className={styles.timeline}>{isLoading ? <div className={styles.loading} style={{ width: "100%" }} /> : null}</div>}
    </div>;
}
