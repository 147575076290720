import React, { useEffect, useRef } from "react";
import ActionButtons from "./ActionButtons";

interface TrackTitleProps {
    title: string,
    buttons?: boolean
}

export default function TrackTitle({title, buttons = false} : TrackTitleProps) {
    const marqueeRef = useRef<HTMLDivElement>(null);
    const marqueeWrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        let wrapperEl: HTMLDivElement | null = null;
        if (
            marqueeRef &&
            marqueeRef.current &&
            marqueeWrapperRef &&
            marqueeWrapperRef.current
        ) {
            const el = marqueeRef.current;
            wrapperEl = marqueeWrapperRef.current;
            if (wrapperEl.offsetWidth < el.offsetWidth) {
                wrapperEl.className = "podcast-text";
            } else {
                wrapperEl.className = "silent-text";
            }
        }
        return () => {
            if (wrapperEl) {
                wrapperEl.className = "silent-text";
            }
        }

    }, [title]);

    return (
        <div className="title-container">
            <div className="silent-text" ref={marqueeWrapperRef}>
                <div ref={marqueeRef}>{title}</div>
            </div>
            {buttons && <ActionButtons />}
        </div>
    );
};
