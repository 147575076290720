import React from "react";
import { SelectLocaleButtonActive } from "./SelectLocaleButtonActive";
import { languages, selectedLanguage } from "../../utils/cache";
import "./SelectLocale.css";
import {useReactiveVar} from "@apollo/client";

interface SelectLocaleProps {
  isOpen: boolean;
  setIsOpen(arg0: string): void;
}

export function SelectLocale({
  isOpen,
  setIsOpen
}: SelectLocaleProps) {
  const lang = useReactiveVar(selectedLanguage);
  return (
    <SelectLocaleButtonActive>
      <span onClick={() => setIsOpen(lang)}>{languages[lang]}</span>
      {/* TODO: react-popper*/}
      {isOpen && (
        <div className="modal-wrapper">
          <ul className="modal">
            {Object.keys(languages).map(l => (
              <li key={l}>
                <button className="modal-btn" key={l} onClick={() => setIsOpen(l)}>
                  {languages[l]}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </SelectLocaleButtonActive>
  );
}
