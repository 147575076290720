import React, { useEffect } from "react";
import { useReactiveVar } from "@apollo/client";
import { languages, selectedLanguage } from "../utils/cache";
import { SelectLocaleButton } from "./SelectLocale";
import ThemeChanger from "./ThemeChanger";
import { useIsMobileDeviceFlag } from "../hooks";
import { Trans } from "@lingui/macro";

interface FooterProps {
  isPlayerOpen: boolean;
  isOpenModal: boolean;
  setModalOpen(value: boolean): void;
  setCoordinates(coordinates: any): void;
}

function getOffset(elem: any) {
  if (elem?.getBoundingClientRect) {
    let box = elem.getBoundingClientRect();
    let body = document.body;
    let docElem = document.documentElement;
    let scrollTop = window.pageYOffset || docElem.scrollTop || body.scrollTop;
    let scrollLeft =
      window.pageXOffset || docElem.scrollLeft || body.scrollLeft;
    let clientTop = docElem.clientTop || body.clientTop || 0;
    let clientLeft = docElem.clientLeft || body.clientLeft || 0;
    return {
      top: Math.round(box.top + scrollTop - clientTop),
      left: Math.round(box.left + scrollLeft - clientLeft)
    };
  } else {
    let top = 0,
      left = 0;
    while (elem) {
      top = top + parseFloat(elem.offsetTop);
      left = left + parseFloat(elem.offsetLeft);
      elem = elem.offsetParent;
    }
    return { top: top, left: left };
  }
}

function Footer({
  isPlayerOpen,
  isOpenModal,
  setModalOpen,
  setCoordinates
}: FooterProps) {
  const lang = useReactiveVar(selectedLanguage);
  const isMobile = useIsMobileDeviceFlag();
  const language = useReactiveVar(selectedLanguage);
  const showRussian = ["ru", "kk", "be"].includes(language);

  let footerPadding = isPlayerOpen
    ? { paddingBottom: "140px" }
    : { paddingBottom: "48px" };

  if (isMobile && isPlayerOpen) {
    footerPadding = { paddingBottom: "170px" };
  }

  const updateCoordinates = () => {
    setCoordinates(getOffset(document.getElementById("locale")));
  };

  useEffect(() => {
    updateCoordinates();
  }, [isOpenModal]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.addEventListener("resize", updateCoordinates);
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  const mailto = (['ru', 'kk'].includes(language)) ?  'mailto:mail@podcast.ru' : 'mailto:mail@pc.st';

  return (
    <footer className="footer is-covered" style={footerPadding}>
      <div className="container">
        <div className="actions">
          <a
            href={mailto}
            title="email"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/img/envelope.svg" alt="email" width={40} height={40} />
          </a>
          <div className="left-actions">
            <ThemeChanger />
            <div id="locale">
              <SelectLocaleButton onClick={() => setModalOpen(true)}>
                {languages[lang]}
              </SelectLocaleButton>
            </div>
          </div>
        </div>
        <div>
          <p style={{ lineHeight: "16px" }}>© 2020–{new Date().getFullYear()} PC.ST</p>
          {showRussian ? <p style={{ lineHeight: "16px" }}>
            <Trans>Все о подкастах</Trans> —{" "}
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a href="https://podcasts.ru/" target="_blank">
              podcasts.ru
            </a>
          </p> : <></>}
          <div className="developers">
            <p style={{ marginTop: 0, lineHeight: "16px" }}>
              <Trans>Разработка</Trans> —{" "}
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <a href="https://pkozlov.ru/" target="_blank">
                <Trans>Павел Козлов</Trans>
              </a>
            </p>
            <p style={{ marginTop: 0, lineHeight: "16px" }}>
              <Trans>Дизайн</Trans> —&nbsp;
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <a href="https://bonkers.digital/" target="_blank">Bonkers!</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
