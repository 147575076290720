import styles from './Logos.module.css';

export const PcstLogo = () => (
  <svg className={styles.logo} viewBox="0 0 42 19" fill="none">
    <path className={styles.logoColorFill}
      d="M0 17.9548V0.253113H4.14253C6.93009 0.253113 8.33541 1.71995 8.33541 4.62874V6.3736C8.33541 9.28239 6.93009 10.7492 4.14253 10.7492H2.81273V17.9569H0V17.9548ZM2.81273 8.22012H4.14253C5.06333 8.22012 5.52268 7.76575 5.52268 6.55203V4.45031C5.52268 3.23659 5.06333 2.78222 4.14253 2.78222H2.81273V8.22012Z"/>
    <path className={styles.logoColorFill}
      d="M9.67297 4.35073C9.67297 1.5934 11.1307 0 13.8155 0C16.5003 0 17.9559 1.5934 17.9559 4.35073V6.22215H15.2963V4.17438C15.2963 3.08721 14.837 2.53118 13.891 2.53118C12.9451 2.53118 12.4857 3.08721 12.4857 4.17438V14.0626C12.4857 15.1498 12.9451 15.6809 13.891 15.6809C14.837 15.6809 15.2963 15.1498 15.2963 14.0626V11.4816H17.9559V13.8593C17.9559 16.6166 16.4982 18.21 13.8155 18.21C11.1307 18.21 9.67297 16.6166 9.67297 13.8593V4.35073Z"/>
    <path
      d="M24.4669 13.7576V12.7451H27.1265V13.9589C27.1265 15.0979 27.6131 15.6788 28.5843 15.6788C29.5806 15.6788 30.0672 15.0979 30.0672 13.9091C30.0672 13.1746 29.8889 12.5439 29.5302 11.9879C29.1988 11.4318 28.5591 10.7492 27.6131 9.94008C26.4637 8.92761 25.6436 8.01887 25.1591 7.18275C24.6997 6.34871 24.469 5.4379 24.469 4.40053C24.4669 1.5934 25.9246 0 28.6598 0C31.3446 0 32.7499 1.5934 32.7499 4.45032V5.18478H30.0903V4.27397C30.0903 3.11004 29.6309 2.52911 28.685 2.52911C27.739 2.52911 27.2524 3.08514 27.2524 4.22417C27.2524 4.85697 27.4306 5.46279 27.7893 5.99393C28.148 6.52506 28.7856 7.20765 29.7316 8.0168C30.9083 9.02927 31.7263 9.96498 32.1605 10.799C32.6198 11.6331 32.8506 12.5958 32.8506 13.7078C32.8506 16.6166 31.3676 18.21 28.6073 18.21C25.8743 18.2079 24.4669 16.6145 24.4669 13.7576Z"
      fill="#1E8BE4"/>
    <path d="M35.9717 17.9548V2.78222H33.031V0.253113H41.7251V2.78222H38.7845V17.9548H35.9717Z" fill="#1E8BE4"/>
    <path d="M22.7276 15.1787H19.9149V17.961H22.7276V15.1787Z" fill="#1E8BE4"/>
  </svg>
);

export const PodcastRuLogo = () => (
  <svg className={styles.logo} viewBox="0 0 116 24" fill="none">
    <g clipPath="url(#clip0_1597_1750)">
      <path
        d="M23.4087 5.0237C23.4087 2.24795 21.1334 0 18.33 0H17.3789C14.5755 0 12.3029 2.24795 12.3029 5.0237V6.97903C12.3029 7.06108 12.3057 7.14038 12.3084 7.22242C12.3057 7.30447 12.3029 7.38377 12.3029 7.46582V8.91796V9.41841V10.4084V10.8706V12H14.4151H15.9689H16.0048V7.68459V7.567V7.3072V7.15132V6.82042V6.6536V6.16955C16.0048 6.14221 16.0048 6.11486 16.0076 6.08751C16.0048 6.06016 16.0048 6.03282 16.0048 6.00547V5.3546C16.0048 4.43026 16.7624 3.68095 17.6968 3.68095H18.012C18.9465 3.68095 19.704 4.43026 19.704 5.3546V6.00547C19.704 6.03282 19.704 6.06016 19.7013 6.08751C19.7013 6.11486 19.704 6.14221 19.704 6.16955V6.6536V6.82042V7.15132V7.3072V7.567V7.68459V12.0027H19.74H21.2384H23.406V10.8733V10.4111V9.42115V8.92069V7.46855C23.406 7.38651 23.4032 7.3072 23.4004 7.22516C23.4032 7.14585 23.406 7.06381 23.406 6.98177V5.0237H23.4087Z"
        fill="#1185D9"/>
      <path className={styles.logoColorFill}
        d="M16.002 18.9763C16.002 21.7493 18.2746 24 21.0808 24H22.0318C24.8352 24 27.1106 21.7521 27.1106 18.9763V17.021C27.1106 16.9389 27.1078 16.8596 27.1051 16.7776C27.1078 16.6983 27.1106 16.6162 27.1106 16.5342V15.082V14.5816V13.5916V13.1294V12H24.9984H23.4446H23.4086V16.3181V16.4357V16.6955V16.8514V17.1823V17.3491V17.8332C23.4086 17.8605 23.4086 17.8879 23.4059 17.9152C23.4086 17.9426 23.4086 17.9699 23.4086 17.9973V18.6481C23.4086 19.5725 22.6511 20.3218 21.7166 20.3218H21.4015C20.467 20.3218 19.7095 19.5725 19.7095 18.6481V17.9973C19.7095 17.9699 19.7095 17.9426 19.7122 17.9152C19.7122 17.8879 19.7095 17.8605 19.7095 17.8332V17.3491V17.1823V16.8514V16.6955V16.4357V16.3181V12H19.6735H18.175H16.0075V13.1294V13.5916V14.5816V15.082V16.5342C16.0075 16.6162 16.0103 16.6983 16.013 16.7776C16.0103 16.8569 16.0075 16.9389 16.0075 17.021V18.9763H16.002Z"/>
      <path className={styles.logoColorFill}
        d="M0 23.6663V0.333603H5.46031C9.13461 0.333603 10.987 2.26706 10.987 6.10115V8.40106C10.987 12.2352 9.13461 14.1686 5.46031 14.1686H3.70748V23.6691H0V23.6663ZM3.70748 10.835H5.46031C6.67401 10.835 7.27949 10.2361 7.27949 8.63625V5.86596C7.27949 4.26615 6.67401 3.66724 5.46031 3.66724H3.70748V10.835Z"/>
      <path className={styles.logoColorFill}
        d="M28.4655 23.6663V0.333603H34.1277C37.802 0.333603 39.6543 2.26706 39.6543 6.10115V17.9015C39.6543 21.7356 37.802 23.6691 34.1277 23.6691H28.4655V23.6663ZM32.173 20.3327H34.0613C35.3082 20.3327 35.9468 19.567 35.9468 18.0656V5.93433C35.9468 4.4357 35.3082 3.66724 34.0613 3.66724H32.173V20.3327Z"/>
      <path className={styles.logoColorFill}
        d="M41.0339 5.73473C41.0339 2.10027 42.9554 0 46.4942 0C50.0331 0 51.9518 2.10027 51.9518 5.73473V8.20146H48.4461V5.50228C48.4461 4.06928 47.8407 3.33637 46.5938 3.33637C45.3469 3.33637 44.7414 4.06928 44.7414 5.50228V18.536C44.7414 19.969 45.3469 20.6691 46.5938 20.6691C47.8407 20.6691 48.4461 19.969 48.4461 18.536V15.134H51.9518V18.268C51.9518 21.9025 50.0303 24.0027 46.4942 24.0027C42.9554 24.0027 41.0339 21.9025 41.0339 18.268V5.73473Z"/>
      <path className={styles.logoColorFill}
        d="M61.2882 23.6663L60.6468 19.0993H56.367L55.7256 23.6663H52.2891L56.132 0.333603H61.1527L64.9957 23.6663H61.2882ZM56.8398 15.9325H60.1768L58.4931 4.16496L56.8398 15.9325Z"/>
      <path className={styles.logoColorFill}
        d="M65.1614 18.134V16.7995H68.6671V18.3993C68.6671 19.9006 69.3085 20.6664 70.5886 20.6664C71.9018 20.6664 72.5432 19.9006 72.5432 18.3336C72.5432 17.3655 72.3082 16.5342 71.8354 15.8013C71.3986 15.0684 70.5554 14.1686 69.3085 13.1021C67.7934 11.7675 66.7124 10.5697 66.0738 9.46764C65.4683 8.36828 65.1642 7.16773 65.1642 5.80036C65.1614 2.10027 67.0829 0 70.6881 0C74.2269 0 76.0793 2.10027 76.0793 5.866V6.83409H72.5736V5.63355C72.5736 4.09936 71.9682 3.33364 70.7213 3.33364C69.4744 3.33364 68.833 4.06655 68.833 5.56791C68.833 6.40201 69.068 7.20055 69.5407 7.90064C70.0135 8.60073 70.854 9.50045 72.1009 10.567C73.6519 11.9015 74.7301 13.1349 75.3024 14.2343C75.9079 15.3336 76.212 16.6026 76.212 18.0684C76.212 21.9025 74.2573 24.0027 70.619 24.0027C67.0165 24 65.1614 21.8997 65.1614 18.134Z"/>
      <path className={styles.logoColorFill} d="M80.326 23.6663V3.66724H76.4498V0.333603H87.9096V3.66724H84.0335V23.6663H80.326Z" />
      <path className={styles.logoColorFill} d="M89.7978 20.0072H86.0903V23.6745H89.7978V20.0072Z" />
      <path className={styles.logoColorFill}
        d="M91.8465 23.6663V0.333603H97.3399C101.147 0.333603 102.833 2.06742 102.833 5.69915V7.13214C102.833 9.56606 102.023 11.1002 100.406 11.732V11.7976C102.057 12.2981 102.867 13.9635 102.867 16.7311V20.8304C102.867 22.0966 103.002 23.0291 103.27 23.6636H99.4964C99.2614 22.9963 99.1591 22.031 99.1591 20.7976V16.5314C99.1591 14.3983 98.5536 13.6654 96.834 13.6654H95.5539V23.6663H91.8465ZM95.5539 10.3345H96.9031C98.385 10.3345 99.1259 9.63442 99.1259 7.86779V6.06833C99.1259 4.46852 98.4845 3.66724 97.2376 3.66724H95.5539V10.3345Z"/>
      <path className={styles.logoColorFill}
        d="M104.147 0.333603H107.854V18.3992C107.854 19.9006 108.496 20.6663 109.743 20.6663C110.989 20.6663 111.631 19.9006 111.631 18.3992V0.333603H115.203V18.134C115.203 21.8997 113.281 24 109.676 24C106.071 24 104.149 21.8997 104.149 18.134V0.333603H104.147Z"/>
    </g>
    <defs>
      <clipPath id="clip0_1597_1750">
        <rect width="115.2" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export const PodcastKzLogo = () => (
  <svg className={styles.logo} viewBox="0 0 116 24" fill="none">
    <g clip-path="url(#clip0_1598_1852)">
      <path
        d="M23.41 5.02C23.41 2.24 21.13 0 18.33 0H17.38C14.58 0 12.3 2.25 12.3 5.02V6.98C12.3 7.06 12.3 7.14 12.31 7.22C12.31 7.3 12.3 7.38 12.3 7.46V8.91V9.41V10.4V10.86V12H14.41H15.96H16V7.68V7.57V7.31V7.15V6.82V6.65V6.17C16 6.14 16 6.12 16 6.09C16 6.06 16 6.04 16 6.01V5.35C16 4.43 16.76 3.68 17.69 3.68H18.01C18.94 3.68 19.7 4.43 19.7 5.35V6C19.7 6.03 19.7 6.05 19.7 6.08C19.7 6.11 19.7 6.13 19.7 6.16V6.64V6.81V7.14V7.3V7.56V7.68V12H19.74H21.24H23.41V10.87V10.41V9.42V8.92V7.47C23.41 7.39 23.41 7.31 23.4 7.23C23.4 7.15 23.41 7.07 23.41 6.99V5.02Z"
        fill="#1185D9"/>
      <path className={styles.logoColorFill}
        d="M16 18.98C16 21.75 18.27 24 21.08 24H22.03C24.83 24 27.11 21.75 27.11 18.98V17.02C27.11 16.94 27.11 16.86 27.1 16.78C27.1 16.7 27.11 16.62 27.11 16.54V15.09V14.59V13.6V13.14V12H25H23.45H23.41V16.32V16.44V16.7V16.86V17.19V17.36V17.84C23.41 17.87 23.41 17.89 23.41 17.92C23.41 17.95 23.41 17.97 23.41 18V18.65C23.41 19.57 22.65 20.32 21.72 20.32H21.4C20.47 20.32 19.71 19.57 19.71 18.65V18C19.71 17.97 19.71 17.95 19.71 17.92C19.71 17.89 19.71 17.87 19.71 17.84V17.36V17.19V16.86V16.7V16.44V16.32V12H19.67H18.17H16V13.13V13.59V14.58V15.08V16.53C16 16.61 16 16.69 16.01 16.77C16.01 16.85 16 16.93 16 17.01V18.98Z"/>
      <path className={styles.logoColorFill}
        d="M0 23.67V0.330017H5.46C9.13 0.330017 10.99 2.26002 10.99 6.10002V8.40002C10.99 12.23 9.14 14.17 5.46 14.17H3.71V23.67H0ZM3.71 10.84H5.46C6.67 10.84 7.28 10.24 7.28 8.64002V5.87002C7.28 4.27002 6.67 3.67002 5.46 3.67002H3.71V10.84Z"/>
      <path className={styles.logoColorFill}
        d="M28.47 23.67V0.330017H34.13C37.8 0.330017 39.66 2.26002 39.66 6.10002V17.9C39.66 21.73 37.81 23.67 34.13 23.67H28.47ZM32.17 20.33H34.06C35.31 20.33 35.95 19.56 35.95 18.06V5.93002C35.95 4.43002 35.31 3.66002 34.06 3.66002H32.17V20.33Z"/>
      <path className={styles.logoColorFill}
        d="M41.03 5.73C41.03 2.1 42.96 0 46.49 0C50.03 0 51.95 2.1 51.95 5.73V8.2H48.44V5.5C48.44 4.07 47.83 3.33 46.59 3.33C45.35 3.33 44.74 4.06 44.74 5.5V18.53C44.74 19.96 45.35 20.66 46.59 20.66C47.83 20.66 48.44 19.96 48.44 18.53V15.13H51.95V18.26C51.95 21.89 50.03 23.99 46.49 23.99C42.95 23.99 41.03 21.89 41.03 18.26V5.73Z"/>
      <path className={styles.logoColorFill}
        d="M61.29 23.67L60.65 19.1H56.37L55.73 23.67H52.29L56.13 0.340027H61.15L65 23.67H61.29ZM56.84 15.93H60.18L58.49 4.16003L56.84 15.93Z"/>
      <path className={styles.logoColorFill}
        d="M65.16 18.13V16.8H68.67V18.4C68.67 19.9 69.31 20.67 70.59 20.67C71.9 20.67 72.54 19.9 72.54 18.34C72.54 17.37 72.3 16.54 71.83 15.81C71.39 15.08 70.55 14.18 69.3 13.11C67.78 11.78 66.7 10.58 66.07 9.48001C65.46 8.38001 65.16 7.18001 65.16 5.81001C65.16 2.11001 67.08 0.0100098 70.68 0.0100098C74.22 0.0100098 76.07 2.11001 76.07 5.88001V6.85001H72.56V5.65001C72.56 4.12001 71.95 3.35001 70.71 3.35001C69.46 3.35001 68.82 4.08001 68.82 5.58001C68.82 6.41001 69.06 7.21001 69.53 7.91001C70 8.61001 70.84 9.51001 72.09 10.58C73.64 11.91 74.72 13.15 75.29 14.25C75.9 15.35 76.2 16.62 76.2 18.08C76.2 21.91 74.25 24.01 70.61 24.01C67.02 24 65.16 21.9 65.16 18.13Z"/>
      <path className={styles.logoColorFill} d="M80.33 23.67V3.67002H76.45V0.330017H87.91V3.66002H84.03V23.66H80.33V23.67Z" />
      <path className={styles.logoColorFill} d="M89.62 20.15H86.09V23.68H89.62V20.15Z" />
      <path className={styles.logoColorFill}
        d="M100.12 0.369995L95.26 10.53V0.369995H91.6V23.67H95.26V16.52L96.39 14.39L99.96 23.67H103.72L98.62 10.76L103.72 0.369995H100.12Z"/>
      <path className={styles.logoColorFill} d="M114.87 0.369995H104.48V3.7H111.11L104.15 20.41V23.67H114.87V20.35H107.91L114.87 3.64V0.369995Z"/>
    </g>
    <defs>
      <clipPath id="clip0_1598_1852">
        <rect width="115.2" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);
