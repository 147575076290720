import { Helmet } from "react-helmet-async";
import { host, languages } from "./cache";
import { hostTitle } from "./index";
import { t } from "@lingui/macro";

type Props = {
    title?: string
    // og_title?: string
    description?: string
    url?: string
    image?: string
    backgroundColor?: string
    backgroundColorDark?: string
}

export default function Head(props : Props) {
    const originalUrl = props.url || "";
    const site_name = hostTitle();
    const image = props.image || `https://${host()}/img/logo.svg`;
    const url = `https://${host()}/${originalUrl}`;
    const title = props.title ? `${props.title} – ${hostTitle()}` : hostTitle();
    const og_title = title;
    let description = props.description;
    if (!description) {
        description = t`Podcast.ru создаёт удобную и красивую страницу для вашего подкаста со ссылками на все популярные сервисы.`;
        description = description.replace('Podcast.ru', hostTitle());
    }
    description = description.replace("\n", " ")
    description = description.replace("\r", "")

    const bg = props.backgroundColor || "#ffffff";
    const bgDark = props.backgroundColorDark || "#021422";

    const langTags = Object.keys(languages).map((lang) => {
        return <link rel="alternate" href={`https://pc.st/${lang}/${originalUrl}`} hrefLang={lang} key={`alter-${lang}`}/>;
    });
    langTags.push(<link rel="alternate" href={`https://podcast.ru/${originalUrl}`} hrefLang="ru" key={'alter-podcast-ru'}/>)
    langTags.push(<link rel="alternate" href={`https://podcast.kz/${originalUrl}`} hrefLang="kk" key={'alter-podcast-kz'}/>)
    langTags.push(<link rel="alternate" href={`https://pc.st/${originalUrl}`} hrefLang="x-default" key={'alter-default'}/>)

    return <Helmet>
        <link rel="icon" href="/favicon.ico" key="icon"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
              key="viewport"/>
        <meta name="theme-color" content={bg} media="(prefers-color-scheme: light)"/>
        <meta name="theme-color" content={bgDark} media="(prefers-color-scheme: dark)"/>
        <meta name="description" content={description} key="description"/>
        <meta property="og:title" content={og_title} key="ogTitle"/>
        <meta property="og:description" content={description} key="ogDescription"/>
        <meta property="og:url" content={url} key="ogUrl"/>
        <meta property="og:image" content={image} key="ogImage"/>
        <meta property="og:site_name" content={site_name} key="ogSiteName"/>
        <meta property="og:image:width" content="1200" key="ogImageWidth"/>
        <meta property="og:image:height" content="600" key="ogImageHeight"/>

        <meta name="twitter:card" content="summary_large_image" key="twitterCard"/>
        <meta name="twitter:site" content={site_name} key="twitterSite"/>
        <meta name="twitter:creator" content={site_name} key="twitterCreator"/>
        <meta name="twitter:title" content={og_title} key="twitterTitle"/>
        <meta name="twitter:description" content={description} key="twitterDescription"/>
        <meta name="twitter:image" content={image} key="twitterImage"/>

        <link rel="apple-touch-icon" href="/apple-touch-icon.png" key="linkApple"/>
        <link rel="manifest" href="/manifest.json" key="linkManifest"/>
        {langTags}
        <title>{title}</title>
    </Helmet>;
}
