import { useState, useEffect, MouseEvent, ChangeEvent, KeyboardEvent } from 'react';
import { t, Trans } from "@lingui/macro";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Slider from '@mui/material/Slider';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
// import ReactCrop, {
//   centerCrop,
//   makeAspectCrop,
//   Crop,
//   PixelCrop,
// } from 'react-image-crop'
// import 'react-image-crop/dist/ReactCrop.css'
// import 'react-color-palette/lib/css/styles.css';
import './ColorPallete.css';
import './SettingsModal.css';

/* images */
// import playerPreviewImage from './../../assets/img/player-preview.svg';
// import playerPreviewLogo from './../../assets/img/widget-image.svg';
// import playerPreviewLinks from './../../assets/img/preview-links.svg';
// import playerPreviewEpisodes from './../../assets/img/preview-episodes.svg';
// import appearenceWidthIcon from './../../assets/img/appearence-width.svg';
// import appearenceBackgroundIcon from './../../assets/img/appearence-background.svg';
// import appearencePlatformsIcon from './../../assets/img/appearence-platforms.svg';
// import appearenceAgeIcon from './../../assets/img/appearence-age.svg';
// import settingsIcon from './../../assets/img/settings-icon.svg';
// import dropdownIcon from './../../assets/img/dropdown-icon.svg';
// import copyIcon from './../../assets/img/copy.svg';
// import linkIcon from './../../assets/img/link-icon.svg';
// import resizeIcon from './../../assets/img/resize.svg';
// import deleteIcon from './../../assets/img/delete.svg';
// import scaleUpIcon from './../../assets/img/scale-up.svg';
// import scaleDownIcon from './../../assets/img/scale-down.svg';
// import checkmarkIcon from './../../assets/img/checkmark.svg';

import CodeSettings from './components/CodeSettings';
import ColorSettings from './components/ColorSettings';

interface SettingsModalProps {
  open: boolean
  handleClose: any
  url: string
  color: string
  artwork: string
  textColor: string
}

/* modal*/
export default function SettingsModal(props: SettingsModalProps) {
  const [type, setType] = useState('episode'); // podcast type: episode or podcast

  const [background, setBackground] = useState(props.color); // preview background
  const [buttonsColor, setButtonsColor] = useState(props.textColor !== '#ffffff' ? '#1E8BE4' : '#ffffff'); // preview background
  const [width, setWidth] = useState(660); // preview width, 0 is adaptive

  const [platformsType, setPlatformsType] = useState('down'); // platforms position: down, menu or hide

  const url = type === 'episode' ? props.url : `big/${props.url}`;
  const height = type === 'episode' ? (platformsType === 'down' ? 258 : 210) : (platformsType === 'down' ? 550 : 502);

  // podcast type handler
  const changeType = (
    event: MouseEvent<HTMLElement>,
    newType: string,
  ) => {
    setType(newType);
  };

  // podcast type handler
  const changePlatformsType = (
    newType: string,
  ) => {
    setPlatformsType(newType);
  };

  // background handler
  const changeBackground = (
    event: MouseEvent<HTMLElement>
  ) => {
    let newBackground = (event.target as HTMLInputElement).dataset.value;
    if (newBackground === undefined) {
      newBackground = '#F3F3F3';
    }
    setBackground(newBackground);
  };
  // buttons color handler
  const changeButtonsColor = (
    event: MouseEvent<HTMLElement>
  ) => {
    let newButtonsColor = (event.target as HTMLInputElement).dataset.value;
    if (newButtonsColor === undefined) {
      newButtonsColor = '#1E8BE4';
    }
    setButtonsColor(newButtonsColor);
  };

  const changeWidth = (
    event: any
  ) => {
    let newWidth = parseInt((event.target).value);
    if (isNaN(newWidth)) {
      newWidth = 0;
    }
    setWidth(newWidth);
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 792,
    height: '90%',
    overflow: 'auto',
    boxShadow: '0px 16px 40px rgba(0, 0, 0, 0.15)',
    borderRadius: '8px',
    // bgcolor: '#FFFFFF',
    padding: 0
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="modal-container">
            <SettingsModalHeader onClose={props.handleClose} type={type} changeType={changeType}></SettingsModalHeader>
            <SettingsModalPreview 
              type={type}
              platformsType={platformsType}
              background={background}
              buttonsColor={buttonsColor}
              width={width}
              height={height}
              artwork={props.artwork}
            ></SettingsModalPreview>
            <SettingsModalAppearence 
              type={type}
              color={props.color}
              platformsType={platformsType}
              changePlatformsType={changePlatformsType}
              width={width}
              height={height}
              changeWidth={changeWidth}
              background={background}
              changeBackground={changeBackground}
              buttonsColor={buttonsColor}
              changeButtonsColor={changeButtonsColor}
            />
            <CodeSettings 
              url={url}
              width={width}
              height={height}
              background={background}
              links={platformsType}
            />
          </div>
        </Box>
      </Modal>
    </div>
  );
}

/* header block*/
export function SettingsModalHeader(props: any) {
  // styling switch button
  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
    '& .MuiToggleButtonGroup-grouped': {
      border: '1px solid #1E8BE4',
      borderRadius: '80px',
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      padding: '12px 24px',
      color: '#1E8BE4',
      '&.Mui-selected ': {
        color: '#fff',
        backgroundColor: '#1E8BE4',
      },
      '&:hover': {
        color: '#fff',
        backgroundColor: '#1E8BE4 !important',
      },
      '&:not(:first-of-type)': {
        borderRadius: '0 80px 80px 0'
      },
      '&:first-of-type': {
        borderRadius: '80px 0 0 80px'
      }
    },
  }));

  return (
    <div className="header">
      <div className="title"><Trans>Настройки плеера</Trans></div>
      <div className="toggle">
        <StyledToggleButtonGroup
          value={props.type}
          exclusive
          onChange={props.changeType}
        >
          <ToggleButton value="episode"><Trans>для эпизода</Trans></ToggleButton>
          <ToggleButton value="podcast"><Trans>для подкаста</Trans></ToggleButton>
        </StyledToggleButtonGroup>
      </div>
      <div className="modal-close" onClick={props.onClose}><img src="/assets/img/modal-close.svg" alt="close"/></div>
    </div>
  )
}

function PlayerIcon(props: any) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.807 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9966 8.81846 22.7312 5.76821 20.4815 3.51852C18.2318 1.26883 15.1815 0.00344108 12 0V0ZM16.828 12.894L9.44701 16.584C9.29458 16.6602 9.12523 16.6961 8.95501 16.6885C8.78479 16.6808 8.61936 16.6298 8.4744 16.5402C8.32944 16.4506 8.20977 16.3255 8.12675 16.1767C8.04373 16.0279 8.0001 15.8604 8.00001 15.69V8.308C8.00001 8.13766 8.04353 7.97015 8.12643 7.82134C8.20933 7.67254 8.32887 7.54738 8.47371 7.45773C8.61855 7.36808 8.78389 7.31692 8.95405 7.30909C9.12421 7.30126 9.29355 7.33703 9.44601 7.413L16.829 11.1C16.9962 11.1827 17.1369 11.3105 17.2352 11.469C17.3335 11.6275 17.3856 11.8103 17.3855 11.9968C17.3854 12.1833 17.3331 12.3661 17.2346 12.5244C17.1361 12.6828 16.9953 12.8105 16.828 12.893V12.894Z" fill={props.color}/>
    </svg>
  );
}
function LogoIcon(props: any) {
  return (
    <svg width="27" height="12" viewBox="0 0 27 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 11.832V0.166992H2.68062C4.48445 0.166992 5.39382 1.13361 5.39382 3.05044V4.20026C5.39382 6.11709 4.48445 7.08371 2.68062 7.08371H1.82011V11.8334H0V11.832ZM1.82011 5.41708H2.68062C3.27647 5.41708 3.57371 5.11766 3.57371 4.31784V2.93286C3.57371 2.13304 3.27647 1.83362 2.68062 1.83362H1.82011V5.41708Z" fill="black"/>
      <path d="M6.25928 2.86704C6.25928 1.05002 7.20259 0 8.9399 0C10.6772 0 11.6192 1.05002 11.6192 2.86704V4.10026H9.89814V2.75083C9.89814 2.03441 9.6009 1.668 8.98876 1.668C8.37663 1.668 8.07939 2.03441 8.07939 2.75083V9.26695C8.07939 9.98337 8.37663 10.3334 8.98876 10.3334C9.6009 10.3334 9.89814 9.98337 9.89814 9.26695V7.56614H11.6192V9.13296C11.6192 10.95 10.6759 12 8.9399 12C7.20259 12 6.25928 10.95 6.25928 9.13296V2.86704Z" fill="black"/>
      <path d="M15.8325 9.06597V8.39877H17.5536V9.19859C17.5536 9.94919 17.8684 10.332 18.4969 10.332C19.1416 10.332 19.4565 9.94919 19.4565 9.16578C19.4565 8.68178 19.3411 8.26615 19.109 7.89974C18.8946 7.53333 18.4806 7.08351 17.8684 6.5503C17.1247 5.8831 16.594 5.28427 16.2804 4.73328C15.9832 4.18366 15.8339 3.58346 15.8339 2.89985C15.8325 1.05002 16.7758 0 18.5457 0C20.2831 0 21.1924 1.05002 21.1924 2.93267V3.41666H19.4714V2.81645C19.4714 2.04945 19.1742 1.66663 18.562 1.66663C17.9499 1.66663 17.635 2.03304 17.635 2.78364C17.635 3.20064 17.7504 3.59986 17.9825 3.94987C18.2146 4.29987 18.6272 4.74969 19.2393 5.2829C20.0007 5.9501 20.5301 6.56671 20.811 7.11633C21.1083 7.66595 21.2576 8.30033 21.2576 9.03316C21.2576 10.95 20.298 12 18.5118 12C16.7433 11.9986 15.8325 10.9486 15.8325 9.06597Z" fill={props.color}/>
      <path d="M23.2769 11.832V1.83362H21.374V0.166992H27V1.83362H25.0971V11.832H23.2769Z" fill={props.color}/>
      <path d="M14.7073 10.0024H12.8872V11.8359H14.7073V10.0024Z" fill={props.color}/>
    </svg>

  );
}

/* preview block */
export function SettingsModalPreview(props: any) {

  return (
    <div className={`preview ${props.type === 'podcast'? 'podcast' : ''}`}>
      <div className={`preview-container ${props.type === 'podcast'? 'podcast' : ''}`}>
        <div className={`preview-block ${props.type === 'podcast'? 'podcast' : ''} ${props.platformsType === 'down'? 'platforms-on' : ''}`} style={{ background: props.background }}>
          <div className="preview-logo-container">
            <img className="preview-logo-image" style={{top: '12px', scale: '0.9'}} src={props.artwork} alt="" />
            <img className="preview-logo-image" style={{top: '6px', scale: '0.95'}} src={props.artwork} alt="" />
            <img className="preview-logo-image" style={{top: '0px'}} src={props.artwork} alt="" />
          </div>
          <div className="preview-left">
            <div className="preview-top">
              <div className="preview-title-block">
                <div className="preview-title"></div>
                <div className="preview-subtitle"></div>
              </div>
              { props.type === 'episode' && <div className="preview-btn" style={{backgroundColor: props.buttonsColor}}></div>}
            </div>
            <div className="preview-player">
              <div className="preview-player-button">
                <PlayerIcon color={props.buttonsColor}/>
              </div>
              <div className="preview-player-bar" style={{backgroundColor: props.buttonsColor}}></div>
            </div>
            <div className="preview-bottom">
              <div className="preview-bottom-text">
                <div className="preview-bottom-season"></div>
                <div className="preview-bottom-episode"></div>
              </div>
              <div className="preview-bottom-logo">
                <LogoIcon color={props.buttonsColor}/>
              </div>
            </div>
          </div>
        </div>
        { props.type === 'podcast' && (
            <div className={`preview-episodes ${props.platformsType === 'down'? 'platforms-on' : ''}`}>
              <img src="/assets/img/preview-episodes.svg" />
            </div>
          )
        }
        { props.platformsType === 'down' && (
            <div className="preview-links">
              <img src="/assets/img/preview-links.svg" />
            </div>
          )
        }
      </div>
      <div className="preview-size">
        <div className="preview-width">{props.width !== 0? props.width : 'adaptive'}</div>
        <div className="preview-x">x</div>
        <div className="preview-height">{props.height}</div>
      </div>
    </div>
  )
}

/* appearence settings block */
export function SettingsModalAppearence(props: any) {
  const [widthType, setWidthType] = useState('default'); // width type: default, page, custom
  // change width on width type changing
  useEffect(() => {
    if (widthType === 'default') {
      props.changeWidth({target: {value: 660}});
    } else if (widthType === 'page') {
      props.changeWidth({target: {value: 0}}); // 0 is adaptive
    }
  }, [widthType]);

  const [backgroundType, setBackgroundType] = useState('color'); // background type: color, image, video
  const [platformsType, setPlatformsType] = useState(props.platformsType); // platform type: down, menu, hide
  const [seasonsType, setSeasonsType] = useState('hide'); // seasons type: down, menu, hide
  const [ratingCountry, setRatingCountry] = useState('all'); // country for age rating: default - all
  const [ratingAge, setRatingAge] = useState('none'); // rating age: default - none

  // change width on width type changing
  useEffect(() => {
    props.changePlatformsType(platformsType);
  }, [platformsType]);

  // width type handler
  const changeWidthType = (
    event: ChangeEvent<HTMLElement>
  ) => {
    setWidthType((event.target as HTMLInputElement).value);
  };

  // background type handler
  const changeBackgroundType = (
    event: ChangeEvent<HTMLElement>
  ) => {
    setBackgroundType((event.target as HTMLInputElement).value);
  };
  // platforms type handler
  const changePlatformsType = (
    event: ChangeEvent<HTMLElement>
  ) => {
    setPlatformsType((event.target as HTMLInputElement).value);
  };
  // platforms type handler
  const changeSeasonsType = (
    event: ChangeEvent<HTMLElement>
  ) => {
    setSeasonsType((event.target as HTMLInputElement).value);
  };
  // rating country handler
  const changeRatingCountry = (
    event: SelectChangeEvent<unknown>
  ) => {
    setRatingCountry(event.target.value as string);
  };
  // rating age handler
  const changeRatingAge = (
    event: SelectChangeEvent<unknown>
  ) => {
    setRatingAge(event.target.value as string);
  };

  // width type options
  const widthTypes = [
    {value: 'default', label: t`Стандартная`},
    {value: 'page', label: t`На ширину страницы`},
    {value: 'custom', label: <SettingsModalAppearenceCustomLabel text={t`Настраиваемая`} />}
  ]
  // background type options
  const backgroundTypes: [] = [
    // {value: 'color', label: <SettingsModalAppearenceCustomLabel text="Цвет" />},
    // {value: 'image', label: <SettingsModalAppearenceCustomLabel text="Изображение" />},
    // {value: 'video', label: <SettingsModalAppearenceCustomLabel text="Видео" />}
  ];
  // platforms type options
  const platformsTypes = [
    {value: 'down', label: t`Снизу`},
    {value: 'menu', label: t`В меню`},
    {value: 'hide', label: t`Не показывать`}
  ]
  // platforms type options
  const seasonsTypes = [
    {value: 'hide', label: t`Не показывать`},
    {value: 'show', label: <SettingsModalAppearenceCustomLabel text={t`Показывать`} />}
  ]
  // // rating country options
  // const ratingCountries = [
  //   {value: 'all', label: 'Все страны'},
  //   {value: 'ukraine', label: 'Украина'},
  //   {value: 'czech', label: 'Чехия'},
  //   {value: 'poland', label: 'Польша'}
  // ]
  // // rating age options
  // const ratingAges = [
  //   {value: 'none', label: 'Без рейтинга'},
  //   {value: '0', label: '0+'},
  //   {value: '12', label: '12+'},
  //   {value: '18', label: '18+'}
  // ]

  // styling select box
  const StyledSelect = styled(Select)(() => ({
    marginRight: '16px',
    '& .MuiSelect-select': {
      padding: '4px 8px',
      width: '142px',
      backgroundColor: '#fff',
      border: '1px solid #1E8BE4',
      fontFamily: 'Roboto Condensed',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '16px',
      lineHeight: '24px',
      color: '#000000'
    }
  }));

  return (
    <div className="appearence">
      <div className="appearence-title"><Trans>Внешний вид</Trans></div>
      <div className="appearence-container">
        { props.type === 'episode' && (
            <div className="appearence-block">
              <SettingsModalAppearenceItem 
                showSettings={widthType === 'custom'}
                icon="/assets/img/appearence-width.svg"
                label={t`Ширина`}
                value={widthType}
                event={changeWidthType}
                options={widthTypes}
              />
              <SettingsModalWidth 
                show={widthType === 'custom'}
                width={props.width}
                height={props.height}
                changeWidth={props.changeWidth}
              />
            </div>
          )
        }
        <div className="appearence-block">
          <SettingsModalAppearenceItem 
            showSettings={true}
            icon="/assets/img/appearence-background.svg"
            label={t`Фон`}
            value={backgroundType}
            event={changeBackgroundType}
            options={backgroundTypes}
          />
          <ColorSettings 
            show={backgroundType === 'color'}
            color={props.background} 
            changeColor={props.changeBackground}
            defaultColor={props.color}
          />
          {/* <SettingsModalImage show={backgroundType === 'image'} changeBackground={props.changeBackground} /> */}
          {/* <SettingsModalVideo show={backgroundType === 'video'} changeBackground={props.changeBackground} /> */}
        </div>
        <div className="appearence-block">
          <SettingsModalAppearenceItem icon="/assets/img/appearence-platforms.svg" label={t`Платформы`} value={platformsType} event={changePlatformsType} options={platformsTypes} />
        </div>
        {/* { props.type === 'podcast' && (
            <div className="appearence-block">
              <SettingsModalAppearenceItem icon={appearencePlatformsIcon} label="Сезоны" value={seasonsType} event={changeSeasonsType} options={seasonsTypes} />
            </div>
          )
        } */}

        {/* <div className="appearence-block">
          <div className="appearence-item">
            <div className="appearence-label">
              <img className="appearence-label-icon" src={appearenceAgeIcon} alt=""/>
              <div className="appearence-label-text"> Возрастной рейтинг </div>
            </div>
            <div className="appearence-options">
              <StyledSelect
                IconComponent={() => (
                  <img className="custom-dropdown-icon" src={dropdownIcon} alt="" />
                )}
                labelId="countries-label"
                id="countries-select"
                value={ratingCountry}
                defaultValue={'all'}
                onChange={changeRatingCountry}
              >
                {ratingCountries.map((el: any) => <MenuItem value={el.value} key={el.value}> {el.label} </MenuItem>)}
              </StyledSelect>
              <StyledSelect
                IconComponent={() => (
                  <img className="custom-dropdown-icon" src={dropdownIcon} alt="" />
                )}
                labelId="countries-label"
                id="countries-select"
                value={ratingAge}
                defaultValue={'none'}
                onChange={changeRatingAge}
              >
                {ratingAges.map((el: any) => <MenuItem value={el.value} key={el.value}> {el.label} </MenuItem>)}
              </StyledSelect>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

/* component for appearence settings items with radio buttons */
export function SettingsModalAppearenceItem(props: any) {
  // styling radio group
  const StyledRadioGroup = styled(RadioGroup)(() => ({
    '& .MuiFormControlLabel-root': {
      marginRight: '24px',
      '& .MuiRadio-root': {
        padding: 0,
        marginRight: '4px',
        marginLeft: 0
      },
      '& .Mui-checked': {
        color: '#1E8BE4'
      },
      '& .MuiTypography-root': {
        fontFamily: 'Roboto Condensed',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '16px',
        color: '#000000'
      },
      '&:has(.Mui-checked)': {
        '& .MuiTypography-root': {
          color: '#1E8BE4'
        },
        '& .custom-label-divider': {
          color: '#1E8BE4'
        },
        '& .custom-label-icon': {
          filter: 'invert(76%) sepia(76%) saturate(6228%) hue-rotate(185deg) brightness(100%) contrast(100%)'
        }
      }
    },
  }));

  return (
    <div className={`appearence-item ${props.showSettings? 'show-settings' : ''}`}>
      <div className="appearence-label">
        <img className="appearence-label-icon" src={props.icon} alt=""/>
        <div className="appearence-label-text"> {props.label} </div>
      </div>
      <div className="appearence-options">
        <StyledRadioGroup
          row
          name="row-radio-buttons-group"
          value={props.value}
          onChange={props.event}
        >
          {props.options.map((el: any) => <FormControlLabel control={<Radio />} value={el.value} key={el.value} label={el.label} />)}
        </StyledRadioGroup>
      </div>
    </div>
  )
}

/* custom radio button label with settings icon */
export function SettingsModalAppearenceCustomLabel(props: any) {
  return (
    <div className="custom-label"> 
      <div>{props.text}</div>
      <div className="custom-label-divider">|</div>
      <img className="custom-label-icon" src="/assets/img/settings-icon.svg" alt="" />
    </div>
  )
}

/* settings width block */
export function SettingsModalWidth(props: any) {
  // check if input is number
  const checkNumber = (event: KeyboardEvent<HTMLElement>) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <div className={`appearence-item-settings width ${props.show? 'show' : ''}`}>
      <div className="appearence-label-text"> <Trans>Размер</Trans> </div>
      <input className="appearence-label-width" onKeyPress={checkNumber} onChange={props.changeWidth} value={props.width} />
      <div className="appearence-label-x"> x </div>
      <div className="appearence-label-height">{props.height}</div>
    </div>
  )
}

// /* settings image block */
// export function SettingsModalImage(props: any) {
//   const [linked, setLinked] = useState(false);
//   const [imageOpen, setImageOpen] = useState(false); // if color modal is opened
//   const [imageLink, setImageLink] = useState('');

//   // copy button handler
//   const linkHandle = () => {
//     if (imageLink !== '') {
//       setLinked(true);
//       props.changeBackground({target: {dataset: {value: 'url('+imageLink+')'}}});
//     }
//   };

//   const deleteImage = () => {
//     setImageLink('');
//     setLinked(false);
//     props.changeBackground({target: {dataset: {value: '#F3F3F3'}}});
//   }

//   const changeLink = (
//     event: ChangeEvent<HTMLElement>
//   ) => {
//     setImageLink((event.target as HTMLInputElement).value);
//   };

//   const openResize = () => {
//     if (imageLink !== '') {
//       setImageOpen(true);
//     }
//   }
//   const closeResize = () => {
//     setImageOpen(false);
//   }

//   return (
//     <div className={`appearence-item-settings image ${props.show? 'show' : ''}`}>
//       <div className="appearence-image-input">
//         <div className="appearence-label-text appearence-image-label"> Link to Unsplash </div>
//         <input className="appearence-label-image" placeholder="www.unsplash.com/image" onChange={changeLink} value={imageLink} />
//       </div>
//       <div className="appearence-image-buttons">
//         {linked ? <button className="checkmark-btn"><img src="/assets/img/checkmark.svg" alt="" />Image Linked</button> :
//           <button className="copy-btn" onClick={linkHandle}><img src="/assets/img/link-icon.svg" alt="" />Link Image</button>
//         }
//         <div className="image-buttons">
//           <button className="image-btn" onClick={openResize}><img src="/assets/img/resize.svg" /> Resize</button>
//           <button className="image-btn" onClick={deleteImage}><img src="/assets/img/delete.svg" /> Delete</button>
//         </div>
//       </div>

//       <ImageResizeModal src={imageLink} handleClose={closeResize} open={imageOpen}/>
//     </div>
//   )
// }

// /* settings video block */
// export function SettingsModalVideo(props: any) {
//   const [linked, setLinked] = useState(false);
//   const [imageOpen, setImageOpen] = useState(false); // if color modal is opened
//   const [imageLink, setImageLink] = useState('');

//   // copy button handler
//   const linkHandle = () => {
//     if (imageLink !== '') {
//       setLinked(true);
//       props.changeBackground({target: {dataset: {value: 'url('+imageLink+')'}}});
//     }
//   };

//   const deleteImage = () => {
//     setImageLink('');
//     setLinked(false);
//     props.changeBackground({target: {dataset: {value: '#F3F3F3'}}});
//   }

//   const changeLink = (
//     event: ChangeEvent<HTMLElement>
//   ) => {
//     setImageLink((event.target as HTMLInputElement).value);
//   };

//   const openResize = () => {
//     if (imageLink !== '') {
//       setImageOpen(true);
//     }
//   }
//   const closeResize = () => {
//     setImageOpen(false);
//   }

//   return (
//     <div className={`appearence-item-settings image ${props.show? 'show' : ''}`}>
//       <div className="appearence-image-input">
//         <div className="appearence-label-text appearence-image-label"> Link to YouTube or Vimeo </div>
//         <input className="appearence-label-image" placeholder="www.youtube.com/video" onChange={changeLink} value={imageLink} />
//       </div>
//       <div className="appearence-image-buttons">
//         {linked ? <button className="checkmark-btn"><img src="/assets/img/checkmark.svg" alt="" />Video Linked</button> :
//           <button className="copy-btn" onClick={linkHandle}><img src="/assets/img/link-icon.svg" alt="" />Link Video</button>
//         }
//         <div className="image-buttons">
//           <button className="image-btn" onClick={openResize}><img src="/assets/img/resize.svg" /> Resize</button>
//           <button className="image-btn" onClick={deleteImage}><img src="/assets/img/delete.svg" /> Delete</button>
//         </div>
//       </div>

//       <ImageResizeModal src={imageLink} handleClose={closeResize} open={imageOpen}/>
//     </div>
//   )
// }


// export function ImageResizeModal(props: any) {
//   const [crop, setCrop] = useState<Crop>({ // default scale to 50%
//     unit: '%',
//     width: 50,
//     height: 50,
//     x: 25,
//     y: 25
//   })

//   const [scale, setScale] = useState<number>(50);
//   // change image crop scale
//   useEffect(() => {
//     setCrop({width: scale, height: scale, unit: '%', x: crop.x, y: crop.y});
//   }, [scale]);

//   const style = {
//     position: 'absolute' as 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 760,
//     height: '560',
//     overflow: 'none',
//     borderRadius: '8px',
//     bgcolor: '#FFFFFF',
//     padding: 0
//   };

//   const handleScale = (event: Event, newValue: number | number[]) => {
//     setScale(newValue as number);
//   }; 

//   return (
//     <Modal
//       open={props.open}
//       onClose={props.handleClose}
//       aria-labelledby="modal-modal-title"
//       aria-describedby="modal-modal-description"
//     >
//       <Box sx={style}>
//         <div className="header color-picker-header">
//           <div className="title color-picker-title">Resize Video</div>
//           <div className="modal-close color-picker-close" onClick={props.handleClose}><img src="/assets/img/modal-close.svg" alt="close"/></div>
//         </div>
//         <div className="title color-picker-subtitle">Drag the box, zoom in and out to find the perfect position </div>
//         <div className="crop-block">
//           <ReactCrop crop={crop} onChange={(c, pC) => {setCrop(pC); setScale(Math.round(pC.width));}}>
//             <img src={props.src} />
//           </ReactCrop>
//         </div>
//         <div className="image-controls">
//           <button className="copy-btn resize-btn" onClick={props.handleClose}><img src="/assets/img/copy.svg" alt="" />Resize</button>
//           <div className="image-slider">
//             <img className="image-scale" onClick={() => setScale(scale-10)} src="/assets/img/scale-down.svg" />
//             <Slider
//               size="small"
//               defaultValue={50}
//               value={scale}
//               onChange={handleScale}
//               step={10}
//               min={10}
//               max={100}
//             />
//             <img className="image-scale" onClick={() => setScale(scale+10)} src="/assets/img/scale-up.svg" />
//           </div>
//         </div>
//       </Box>
//     </Modal>
//   )
// }
