import React from "react";
import "./SelectLocale.css";

function GlobeIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 31.3599C24.4831 31.3599 31.36 24.483 31.36 15.9999C31.36 7.5168 24.4831 0.639893 16 0.639893C7.51692 0.639893 0.640015 7.5168 0.640015 15.9999C0.640015 24.483 7.51692 31.3599 16 31.3599Z"
        stroke="#1E8BE4"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M16 31.3599C20.2416 31.3599 23.68 24.483 23.68 15.9999C23.68 7.5168 20.2416 0.639893 16 0.639893C11.7585 0.639893 8.32001 7.5168 8.32001 15.9999C8.32001 24.483 11.7585 31.3599 16 31.3599Z"
        stroke="#1E8BE4"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M4.07361 25.8804C6.89025 24.1466 11.1866 23.04 16 23.04C20.7475 23.04 24.9914 24.1165 27.8086 25.808"
        stroke="#1E8BE4"
        strokeMiterlimit="10"
      />
      <path
        d="M27.9264 6.12036C25.1098 7.85348 20.8135 8.96004 16 8.96004C11.2532 8.96004 7.00869 7.88356 4.19141 6.19204"
        stroke="#1E8BE4"
        strokeMiterlimit="10"
      />
      <path d="M0.640015 16H31.36" stroke="#1E8BE4" strokeMiterlimit="10" />
      <path d="M16 0.639893V31.3599" stroke="#1E8BE4" strokeMiterlimit="10" />
    </svg>
  );
}

interface SelectLocaleProps {
  className?: string;
  onClick?(): void;
}

export const SelectLocaleButton: React.FC<SelectLocaleProps> = ({
  onClick,
  className = "",
  children
}) => {
  return (
    <button className={className || "language"} onClick={onClick}>
      {GlobeIcon()}
      {children}
    </button>
  );
};
