import React from "react";
import { host } from "../../utils/cache";
import { LINK_TITLES } from "../../locales/terms";

import "./ResourceLink.css";

interface ResourceLinkProps {
  href: string;
  title: string;
  img: string;
  url: string;
}

export function ResourceLink({ href, img, title, url }: ResourceLinkProps) {
  const translated_title = LINK_TITLES[title] || title;
  // const host = window.location.host || window.location.hostname || "pc.st";
  const full_href = href.replace("{domain}", host()).replace("{url}", url);
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <div className="resource-link">
      <div className="resource link-content">
        <img src={img} alt={translated_title} />
        <div className="resource-info">
          <span>{translated_title}</span>
          <a href={full_href} title={translated_title} target="_blank" rel="noreferrer">{full_href}</a>
        </div>
      </div>
    </div>
  );
}
