// import * as Sentry from "@sentry/react";
import {i18n} from '@lingui/core'
import {I18nProvider} from '@lingui/react'
import {Route, Routes} from "react-router-dom";
import {languages, selectedLanguage} from "./utils/cache";
import I18nLoader from "./utils/I18nLoader";
import Search from "./pages/Search";
import EpisodePage from "./pages/EpisodePage";
import PodcastPage from "./pages/PodcastPage";
import {useEffect, useRef} from "react";
import InfoTab from "./pages/PodcastPage/InfoTab";
import EpisodeListTab from "./pages/PodcastPage/EpisodeListTab";
import AboutTab from "./pages/PodcastPage/AboutTab";
import {useReactiveVar} from "@apollo/client";

function App() {
  const language = useReactiveVar(selectedLanguage);
  const firstRender = useRef(true)

  if (language && firstRender.current) {
    i18n.activate(language)
    firstRender.current = false
  }

  useEffect(() => {
    i18n.activate(language);
  }, [language]);

  const appRoutes = <>
    <Route index element={<Search/>}/>
    <Route path="e/:slug" element={<EpisodePage/>}/>
    <Route path=":slug" element={<PodcastPage/>}>
      <Route index element={<InfoTab/>}/>
      <Route path="e" element={<EpisodeListTab/>}/>
      <Route path="info" element={<AboutTab/>}/>
    </Route>
  </>;
  const langRoutes = Object.keys(languages).map((locale) =>
    <Route path={`/${locale}/`} element={<I18nLoader locale={locale}/>} key={locale}>
      {appRoutes}
    </Route>);
  return (
    <I18nProvider i18n={i18n}>
      <Routes>
        <Route path="/" element={<I18nLoader locale={undefined}/>}>
          {appRoutes}
        </Route>
        {langRoutes}
      </Routes>
    </I18nProvider>
  );
}

// export default Sentry.withProfiler(App);
export default App;
