import { useState } from "react";
import { ResourceLink } from "../../components/ResourceLink";
import { autolinker } from "../../utils";
import { useOutletContext } from "react-router-dom";
import { Podcast } from "../../types/api";
import Head from "../../utils/Head";
import { t, Trans } from "@lingui/macro";
import {currentUrl, host} from "../../utils/cache";
import SettingsModal from "../../components/SettingsModal/SetingsModal";

function AboutTab() {
    const podcast = useOutletContext<Podcast>();
    const links = podcast.links as any[];
    const [openModal, setOpenModal] = useState(false);

    // @ts-ignore
    const handleOpen = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setOpenModal(true);
    }
    const handleClose = () => setOpenModal(false);
    // if (host().toLowerCase() !== 'pc.st') {
    //     links.push({
    //         title: 'SHORT_LINK',
    //         href: `https://pc.st/${podcast.id}`,
    //
    //     })
    // }

    const url = `${podcast.id}`;

    currentUrl(`${podcast.id}/info`);
    const ogHost = host() !== 'podcast.kz' ? `og.${host()}` : 'og.pc.st';
    return (
        <div className="container">
            <Head
                title={`${podcast.title} – ${t`О подкасте`}`}
                description={podcast.description}
                image={`https://${ogHost}/${podcast.slug}`}
                url={currentUrl()}
                backgroundColor={podcast.backgroundColor}
                backgroundColorDark={podcast.backgroundColor}
            />
            <div className="about" dangerouslySetInnerHTML={{ __html: autolinker.link(podcast.description_html) }} />

            <div className="resources">
                {links.map(l => (
                    <ResourceLink {...l} key={l.href} url={url} />
                ))}
                <div className="resource-link">
                    <div className="resource link-content">
                        <img src="/img/embed-icon-white.svg" alt="Embed Podcast" />
                        <div className="resource-info">
                            <span><Trans>Добавить подкаст на сайт</Trans></span>
                            <a href="#" title="Embed Podcast" onClick={handleOpen}>Embed Podcast</a>
                        </div>
                    </div>
                </div>
            </div>
            <SettingsModal
              open={openModal}
              handleClose={handleClose}
              url={url}
              color={podcast.backgroundColor!}
              artwork={podcast.artwork}
              textColor={podcast.textColor!}
            />
        </div>
    );
}

export default AboutTab;
