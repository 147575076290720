import styles from './Rings.module.css';

const Rings = ({height = 40}) => (<svg height={height} viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
  <circle cx="50" cy="50" r="32" strokeWidth="8" stroke="#72bbf7"
          strokeDasharray="50.26548245743669 50.26548245743669" fill="none" strokeLinecap="round">
    <animateTransform attributeName="transform" type="rotate" dur="5s" repeatCount="indefinite" keyTimes="0;1"
                      values="0 50 50;360 50 50"></animateTransform>
  </circle>
  <circle cx="50" cy="50" r="23" strokeWidth="8" className={styles.strokeColor}
          strokeDasharray="36.12831551628262 36.12831551628262" strokeDashoffset="36.12831551628262" fill="none"
          strokeLinecap="round">
    <animateTransform attributeName="transform" type="rotate" dur="5s" repeatCount="indefinite" keyTimes="0;1"
                      values="0 50 50;-360 50 50"></animateTransform>
  </circle>
</svg>);

export default Rings;
