import {useEffect, useState, useCallback} from "react";

import AudioManager from "../utils/AudioManager";

export default function usePlayer() {
  const manager = AudioManager.instance;
  const [source, setSource] = useState(manager.source);
  const [title, setTitle] = useState(manager.title);
  const [duration, setDuration] = useState(manager.duration);
  const [isReady, setIsReady] = useState(manager.isReady);
  const [isPlaying, setIsPlaying] = useState(manager.isPlaying);
  const [isLoading, setIsLoading] = useState(manager.isLoading);
  const [isAirplayAvailable, setIsAirplayAvailable] = useState(manager.isAirplayAvailable);

  const changeState = useCallback(() => {
    setSource(manager.source);
    setTitle(manager.title);
    setDuration(manager.duration);
    setIsPlaying(manager.isPlaying);
    setIsReady(manager.isReady);
    setIsLoading(manager.isLoading);
    setIsAirplayAvailable(manager.isAirplayAvailable);
  }, []);

  useEffect(() => {
    const changeStateEventHandler = () => {
      changeState();
    }

    manager.events.addEventListener("statechanged", changeStateEventHandler);
    return () => {
      manager.events.removeEventListener("statechanged", changeStateEventHandler)
    }
  }, [manager])

  const updateTrack = useCallback((
    source: string, title: string, duration: number, force: boolean, position: number = 0,
    author: string = "", album: string = "", artwork: string = "", host = ""
  ) => {
    if (!manager.isPlaying || force) {
      manager.setTrack(source, title, duration, author, album, artwork, host);
      if (force) manager.audio.play().then(() => {
        if (position) manager.audio.currentTime = position;
      });
    }
  }, []);

  const getPosition = useCallback((): number => {
    return manager.audio.currentTime;
  }, []);

  const showPlaybackTargetPicker = useCallback(() => {
    // @ts-ignore
    manager.audio.webkitShowPlaybackTargetPicker();
  }, []);

  const seek = useCallback((position: number) => {
    manager.audio.currentTime = position;
  }, []);

  const togglePlayPause = useCallback(() => {
    manager.togglePlayPause();
  }, []);

  const clean = useCallback(() => {
    manager.clean();
  }, []);

  return {
    updateTrack,
    getPosition,
    showPlaybackTargetPicker,
    seek,
    togglePlayPause,
    clean,
    source,
    title,
    duration,
    isReady,
    isPlaying,
    isLoading,
    isAirplayAvailable
  }
}
