import React, { useEffect } from "react";
import { Link, NavLink, Outlet, useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import { Trans } from "@lingui/macro";

import { playerShareHref } from "../../client";

import { Podcast } from "../../types/api";
import { Maybe } from "graphql/jsutils/Maybe";
import Loading from "../Loading";
import Search from "../Search";
import {statusCode, host, iOSUrl, androidUrl} from "../../utils/cache";
import Layout from "../../layouts/Layout";
import {hostTitle} from "../../utils";
import usePlayer from "../../hooks/usePlayer";


const GET_PODCAST = gql`
  query GetPodcast($slug: String!) {
    podcast(slug: $slug) {
      id
      slug
      title
      description
      description_html
      author
      link
      artwork
      textColor
      backgroundColor
      ogImage
      
      links {
        href
        title
        img
      }

      lastTrack {
        name
        url
        duration
      }

      linkBreaker
      linkCastbox
      linkStitcher
      linkSoundstream
      linkSpotify
      linkCastro
      linkItunes
      linkOvercast
      linkIheartradio
      linkPocketcasts
      linkPlayerfm
      linkVk
      linkRadiopublic
      linkRss
      linkYandex
      linkSberWeb
      linkSberMobile
      linkYoutube
    }
  }
`;

interface QueryPodcastTypes {
  podcast: Maybe<Podcast>;
}

interface MainPageProps {
}

function PodcastPage(props: MainPageProps) {
  const { slug } = useParams<{slug: string}>();
  const { isReady, updateTrack } = usePlayer();
  const { loading, data, error } = useQuery<QueryPodcastTypes>(GET_PODCAST, {
    variables: { slug: slug }
  });

  useEffect(() => {
    if (!isReady && data && data.podcast) {
      updateTrack(
          data.podcast.lastTrack!.url,
          data.podcast.lastTrack!.name,
          parseInt(data.podcast.lastTrack!.duration),
          false,
          0,
          data.podcast.author,
          data.podcast.title,
          data.podcast.artwork,
          host().toUpperCase()
      );
      playerShareHref(`https://${host()}/${data.podcast.slug}`);
    }
  // eslint-disable-next-line
  }, [data]);

  if (loading) return <Loading />;
  if (error) {
    statusCode(500);
    return <Search error500={true} />;
  }
  if (!data || !data.podcast) {
    statusCode(404);
    return <Search error404={true} />;
  }

  statusCode(200);

  const podcast = data.podcast;

  iOSUrl(podcast.linkItunes);
  androidUrl(podcast.linkGoogle || "");

  const backgroundColor =
      `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%), ${data.podcast.backgroundColor}` ||
      "linear-gradient(180deg, #613384 0%, #3f2156 100%)";

  const textColor = data.podcast.textColor || "white";
  const subTextColor = textColor === "#ffffff" ? "rgba(255, 255, 255, 40%)" : "rgba(0, 0, 0, 40%)";

  let logoUrl = (textColor === "#ffffff" ? "/img/pcst-logo-white.svg" : "/img/pcst-logo-black.svg");
  switch (host().toLowerCase()) {
    case "podcast.ru":
      logoUrl = (textColor === "#ffffff" ? "/img/podcast-ru-white.svg" : "/img/podcast-ru-black.svg");
      break;
    case "podcast.kz":
      logoUrl = (textColor === "#ffffff" ? "/img/podcast-kz-white.svg" : "/img/podcast-kz-black.svg");
      break;
  }

  return (<Layout>
    <div>
      <div
        className="header podcast-header"
        style={{
          background: backgroundColor
        }}
      >
        <div className="container">
          <Link to="/" title={hostTitle()}>
            <img
              src={logoUrl}
              alt="logo"
              className="podcast-logo"
              // width={116}
              title={hostTitle()}
              height={24}
            />
          </Link>
          <div className="podcast-info-wrapper">
            <div className="podcast-img-wrapper">
              <img src={podcast.artwork} className="podcast-img" alt="podcast" title={podcast.title} />
            </div>
            <div className="podcast-info">
              <div>
                <h1 style={{ color: textColor }}>{podcast.title}</h1>
                <span className="podcast-author" style={{ color: textColor }}>
                  <Trans>Автор:</Trans>&nbsp;
                  {podcast.link ? (
                    <a
                      href={podcast.link}
                      style={{ color: subTextColor }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {podcast.author}
                    </a>
                  ) : (
                    <span style={{ color: subTextColor }}>
                      {podcast.author}
                    </span>
                  )}
                </span>
              </div>
              <div>
                <ul className="tabs">
                  <NavLink
                    className="tab"
                    to=""
                    style={({isActive }) => ({color: isActive ? textColor : subTextColor})}
                    end
                  >
                    <li>
                      <Trans>Ссылки</Trans>
                    </li>
                  </NavLink>
                  <NavLink
                    className="tab"
                    to="e"
                    style={({isActive }) => ({color: isActive ? textColor : subTextColor})}
                  >
                    <li>
                      <Trans>Эпизоды</Trans>
                    </li>
                  </NavLink>
                  <NavLink
                    className="tab"
                    to="info"
                    style={({isActive }) => ({color: isActive ? textColor : subTextColor})}
                  >
                    <li>
                      <Trans>О подкасте</Trans>
                    </li>
                  </NavLink>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main-page">
        <Outlet context={podcast} />
      </div>
    </div>
  </Layout>);
}

export default PodcastPage;
