export default class AudioManager {
  static #instance: AudioManager;

  readonly #audio: HTMLAudioElement;
  public get audio(): HTMLAudioElement {
    return this.#audio;
  }

  readonly #events: Comment;
  public get events(): Comment {
    return this.#events;
  }

  #source: string;
  public get source(): string {
    return this.#source;
  }

  #title: string;
  public get title(): string {
    return this.#title;
  }

  #duration: number;
  public get duration(): number {
    return this.#duration;
  }

  #isAirplayAvailable: boolean;
  public get isAirplayAvailable(): boolean {
    return this.#isAirplayAvailable;
  }

  #isReady: boolean;
  public get isReady(): boolean {
    return this.#isReady;
  }

  #isLoading: boolean;
  public get isLoading(): boolean {
    return this.#isLoading;
  }

  #isPlaying: boolean;
  public get isPlaying(): boolean {
    return this.#isPlaying;
  }

  #author: string = "";

  public get author(): string {
    return this.#author;
  }

  #album: string = "";

  public get album(): string {
    return this.#album;
  }

  #artwork: string = "";

  public get artwork(): string {
    return this.#artwork;
  }

  #host: string = "PC.ST";

  public get host(): string {
    return this.#host;
  }

  private constructor() {
    if (typeof window === "undefined" || typeof window.document === "undefined") return;
    this.#audio = new Audio();
    this.#audio.preload = 'none';
    this.#audio.autoplay = false;
    this.#source = "";
    this.#title = "";
    this.#duration = 0;
    this.#isAirplayAvailable = false;
    this.#isReady = false;
    this.#isLoading = false;
    this.#isPlaying = false;
    this.#events = new Comment("player-event-bus");

    this.#audio.addEventListener("loadeddata", (event) => {
      this.#isReady = true;
      this.#isLoading = false;
      if (this.#audio.duration) this.#duration = this.#audio.duration;
      this.fireStateChanged();
    });

    this.#audio.addEventListener("waiting", (event) => {
      this.#isLoading = true;
      this.fireStateChanged();
    });

    this.#audio.addEventListener("play", (event) => {
      this.#isPlaying = true;
      if (!this.#isReady) this.#isLoading = true;
      this.fireStateChanged();
    });

    this.#audio.addEventListener("pause", (event) => {
      this.#isPlaying = false;
      this.fireStateChanged();
    });

    // @ts-ignore
    if (window.WebKitPlaybackTargetAvailabilityEvent) {
      this.#audio.addEventListener(
        "webkitplaybacktargetavailabilitychanged",
        // @ts-ignore
        (event) => {
          // @ts-ignore
          switch (event.availability) {
            case "available":
              this.#isAirplayAvailable = true;
              break;
            case "not-available":
              this.#isAirplayAvailable = false;
              break;
          }
          this.fireStateChanged();
        }
      );
    }
  }

  public static get instance(): AudioManager {
    if (!AudioManager.#instance) {
      AudioManager.#instance = new AudioManager();
    }

    return AudioManager.#instance;
  }

  private fireStateChanged() {
    const event = new CustomEvent("statechanged");
    this.#events.dispatchEvent(event);
  }

  public togglePlayPause() {
    if (this.#isPlaying) {
      this.#audio.pause();
    } else {
      this.#audio.play();
    }
  }

  public setTrack(source: string, title: string, duration: number,
                  author: string = "", album: string = "", artwork: string = "", host = "") {
    if (this.#isPlaying) this.#audio.pause();

    this.#audio.src = source;
    this.#source = source;
    this.#title = title;
    this.#duration = duration;
    this.#author = author;
    this.#album = album;
    this.#artwork = artwork;
    this.#host = host;
    this.#isReady = false;
    this.#isPlaying = false;
    this.#isLoading = false;
    this.fireStateChanged();
    this.setMediaSession();
  }

  public setMediaSession() {
    if ("mediaSession" in navigator) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: this.#title,
        artist: `${this.#album} – ${this.#host}`,
        album: this.#author,
        artwork: [
          {
            src: this.#artwork,
            sizes: "512x512"
          },
        ],
      });

      navigator.mediaSession.setActionHandler("play", () => {
        this.#audio.play();
        this.fireStateChanged();
      });
      navigator.mediaSession.setActionHandler("pause", () => {
        this.#audio.pause();
        this.fireStateChanged();
      });
      navigator.mediaSession.setActionHandler("stop", () => {
        this.#audio.pause();
        this.fireStateChanged();
      });
      navigator.mediaSession.setActionHandler("seekbackward", (details) => {
        const skipTime = details.seekOffset || 15;
        this.#audio.currentTime = Math.max(this.#audio.currentTime - skipTime, 0);
      });
      navigator.mediaSession.setActionHandler("seekforward", (details) => {
        const skipTime = details.seekOffset || 30;
        this.#audio.currentTime = Math.min(this.#audio.currentTime + skipTime, this.#audio.duration);
      });
      navigator.mediaSession.setActionHandler("seekto", (details) => {
        if (details.seekTime) this.#audio.currentTime = details.seekTime;
      });
    }
  }

  public clean() {
    if (this.#isPlaying && this.#audio) this.#audio.pause();

    this.#audio.src = "";
    this.#source = "";
    this.#title = "";
    this.#duration = 0;
    this.#isReady = false;
    this.#isPlaying = false;
    this.#isLoading = false;
    this.fireStateChanged();
  }

}
