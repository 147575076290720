import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Trans } from "@lingui/macro";
import Emoji from "react-emoji-render";
import CopyToClipboard from "react-copy-to-clipboard";
import { convert_time } from "../utils";
import { host } from "../utils/cache";
import usePlayer from "../hooks/usePlayer";
import moment from "moment";

import "./EpisodeListItem.css";

// const ShareTransparentIcon = () => (
//   <svg
//     width="24"
//     height="32"
//     viewBox="0 0 26 34"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <g opacity="0.3">
//       <path
//         d="M13.0001 18.1148V1.74268"
//         stroke="#1E8BE4"
//         strokeWidth="1.5"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       />
//       <path
//         d="M7 6.95349L13 1L19 6.95349"
//         stroke="#1E8BE4"
//         strokeWidth="1.5"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       />
//       <path
//         d="M17.5 12.1626H23.5C23.8978 12.1626 24.2794 12.3194 24.5607 12.5985C24.842 12.8777 25 13.2562 25 13.651V31.5114C25 31.9062 24.842 32.2847 24.5607 32.5639C24.2794 32.843 23.8978 32.9998 23.5 32.9998H2.5C2.10218 32.9998 1.72064 32.843 1.43934 32.5639C1.15804 32.2847 1 31.9062 1 31.5114V13.651C1 13.2562 1.15804 12.8777 1.43934 12.5985C1.72064 12.3194 2.10218 12.1626 2.5 12.1626H8.5"
//         stroke="#1E8BE4"
//         strokeWidth="1.5"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       />
//     </g>
//   </svg>
// );

const ShareIcon = () => (
  <svg
    width="12"
    height="16"
    viewBox="0 0 26 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0001 18.1148V1.74268"
      stroke="#1E8BE4"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 6.95349L13 1L19 6.95349"
      stroke="#1E8BE4"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 12.1626H23.5C23.8978 12.1626 24.2794 12.3194 24.5607 12.5985C24.842 12.8777 25 13.2562 25 13.651V31.5114C25 31.9062 24.842 32.2847 24.5607 32.5639C24.2794 32.843 23.8978 32.9998 23.5 32.9998H2.5C2.10218 32.9998 1.72064 32.843 1.43934 32.5639C1.15804 32.2847 1 31.9062 1 31.5114V13.651C1 13.2562 1.15804 12.8777 1.43934 12.5985C1.72064 12.3194 2.10218 12.1626 2.5 12.1626H8.5"
      stroke="#1E8BE4"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

// const EmbedIcon = () => (
//   <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M3.20454 5.60217L0.806641 8.00007L3.20454 10.398M12.7961 5.60217L15.194 8.00007L12.7961 10.398" stroke="#1E8BE4" strokeWidth="1.39676" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
//     <path d="M9.19916 5.20654L6.80127 10.7936" stroke="#1E8BE4" strokeWidth="1.39676" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
//   </svg>
// )

// TODO: enhance props
interface EpisodeProps {
  trackUrl: string;
  title: string;
  date: number;
  duration: number;
  description: string;
  href: string;
  slug: string;
  author: string;
  album: string;
  artwork: string;
}

export default function EpisodeListItem({
  trackUrl,
  duration,
  title,
  date,
  description,
  href,
  slug,
  author,
  album,
  artwork
}: EpisodeProps) {
  const [copied, setCopied] = useState(false);
  const { isPlaying, togglePlayPause, source, updateTrack } = usePlayer();
  const isThisPlaying = source && source === trackUrl && isPlaying;

  const fullUrl = `https://${host()}${href}`;

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        url: fullUrl
      });
    }
  }

  const handlePlay = () => {
    if (isThisPlaying) {
      togglePlayPause();
    } else {
      updateTrack(
        trackUrl,
        title,
        duration,
        true,
        0,
        author,
        album,
        artwork,
        host().toUpperCase()
      );
      togglePlayPause();
    }
  }

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  const showShare = typeof navigator !== 'undefined' && navigator.share;

  return (
    <div className="episode-container">
      <NavLink className="episode-link" to={href}>
        <h2><Emoji text={title} /></h2>
      </NavLink>
      <div className="episode-info">
        {moment.utc(date * 1000).format("DD.MM.YYYY")} • {convert_time(duration)}
      </div>
      <p className="episode-descr">{description.substr(0, 150)}{description.length > 150 ? "..." : ""}</p>
      <div className="episode-action-bar">
        <button className="play-episode img-btn" onClick={handlePlay}>
          <img src={isThisPlaying ? "/img/pause.svg" : "/img/play.svg"} alt="play" width={40} height={40} />
            <span><Trans>Слушать эпизод</Trans></span>
        </button>
        <div className="episode-action-bar-buttons">
          <CopyToClipboard text={fullUrl} onCopy={handleCopy}>
            <div className={copied ? "wrap-copy copied":"wrap-copy"}>
              <div className="link-copy">
                <svg height="16">
                  <use xlinkHref="/img/sprite.svg#copy" />
                </svg>
              </div>
              <div className="popup"><Trans>Ссылка скопирована</Trans></div>
            </div>
          </CopyToClipboard>
          <button className={`img-btn ${!showShare ? 'hidden':''}`} onClick={handleShare}>
            <ShareIcon />
          </button>
          {/* <button className={`img-btn`} onClick={handleShare}>
            <EmbedIcon />
          </button> */}
        </div>
      </div>
    </div>
  );
}
