import { convert_time } from "../../utils";

import useAudioTime from "../../hooks/useAudioTime";
import usePlayer from "../../hooks/usePlayer";

interface TrackTimeProps {
    trackDuration: number
}

export default function TrackTime({ trackDuration } : TrackTimeProps) {
    const { duration, isReady } = usePlayer();
    const position = useAudioTime();
    return <div className="time-container">
        <div className="podcast-time">
            <span>{duration && isReady ? <strong>{convert_time(position)}</strong> : <></>}</span>
            <span>{duration ? convert_time(duration) : trackDuration ? (
                convert_time(trackDuration)
            ) : (<></>)}</span>
        </div>
    </div>;
}
