import { useState } from "react";

import { playerHost } from "../../../utils/cache";

import { Trans } from "@lingui/macro";

// import copyIcon from './../../../assets/img/copy.svg';
// import checkmarkIcon from './../../../assets/img/checkmark.svg';


interface CodeSettingsProps {
    url: string
    width: number
    height: number
    background: string
    links: string
}

export default function CodeSettings(props: CodeSettingsProps) {
    const [copied, setCopied] = useState(false);
    const params: Record<string, string> = {};
    if (props.background) params['b'] = props.background.slice(1);
    if (props.links) params['l'] = props.links.slice(0, 1);
    const paramsObj = new URLSearchParams(params);
    const paramsString = paramsObj.toString();

    const url = `https://${playerHost()}/${props.url}${paramsString ? '?' + paramsString : ''}`;
    const width = props.width ? props.width + 'px' : '100%';
  
    // code for user to paste
    const iframe = `<iframe src="${url}" height="${props.height}" style="width:100%;max-width:${width};overflow:hidden;background:transparent;" frameborder="0" allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"  sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"></iframe>`;
    
    // copy button handler
    const copyHandle = (value: boolean) => {
      if (value) { 
        navigator.clipboard.writeText(iframe)
      }
      setCopied(value);
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    };
  
    return (
      <div className="code-section">
        <div className="code-section-title"><Trans>Код для вставки на страницу</Trans></div>
        <div className="code-container">
          {iframe}
        </div>
        {copied ? <button className="checkmark-btn" onClick={() => copyHandle(false)}><img src="/assets/img/checkmark.svg" alt="" /><Trans>Скопировано в буфер обмена</Trans></button> :
          <button className="copy-btn" onClick={() => copyHandle(true)}><img src="/assets/img/copy.svg" alt="" /><Trans>Скопировать</Trans></button>
        }
      </div>
    )
  }
