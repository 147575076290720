import {InMemoryCache, makeVar} from "@apollo/client";
import axios from "axios-jsonp-pro";
import { SearchResponse } from "../types/itunes";

let defaultLanguage = 'en';
let originalHost = 'pc.st';
let originalPlayerHost = 'e.pc.st';

if (typeof window !== "undefined" && typeof window.document !== "undefined") {
    originalHost = window.location.host || window.location.hostname || originalHost;
    originalPlayerHost = 'e.' + originalHost;
    let storedLanguage: string | null = localStorage.getItem("selectedLanguage");
    switch (originalHost.toLowerCase()) {
        case 'podcast.ru':
            originalPlayerHost = 'e.pc.st';
            defaultLanguage = 'ru';
            storedLanguage = null;
            break;
        case 'podcast.kz':
            originalPlayerHost = 'e.pc.st';
            defaultLanguage = 'kk';
            storedLanguage = null;
            break;
    }
    defaultLanguage = storedLanguage || window.__DEFAULT_LANGUAGE__ || defaultLanguage;
}

export const host = makeVar(originalHost.toLowerCase());
export const playerHost = makeVar(originalPlayerHost.toLowerCase());
export const currentUrl = makeVar('');

export const languages: {[name: string]: string} = {
    en: "English",
    fr: "Français",
    es: "Español",
    de: "Deutsch",
    ru: "Русский",
    be: "Беларуская",
    uk: "Українська",
    kk: "Қазақша"
};

declare global {
    interface Window {
        __APOLLO_STATE__: any;
        __DEFAULT_LANGUAGE__: string;
    }
}

export const selectedLanguage = makeVar<string>(defaultLanguage);//localStorage.getItem("selectedLanguage") || "ru");

export const searchData = makeVar<SearchResponse>({result: []});
export const searchValue = makeVar<string>("");
export const loadError = makeVar<boolean>(false);
export const searchDataLoading = makeVar<boolean>(false);
export const statusCode = makeVar(200);

export const iOSUrl = makeVar("");
export const androidUrl = makeVar("");

let lastTerm: string = "";

export const cacheConfig = {
    typePolicies: {
        Query: {
            fields: {
                itunes(itunes: SearchResponse, { args }: any) {
                    const term = args!.term || "";
                    if (term !== lastTerm) {
                        const url_start = 'https://search.pc.st/?q=';
                        const url = encodeURI(url_start + term);
                        lastTerm = term;
                        loadError(false);
                        searchDataLoading(true);
                        axios.get<SearchResponse>(url).then((response) => {
                            const r = response.data as unknown as SearchResponse;
                            searchData(r);
                            searchDataLoading(false);
                        }).catch(() => {
                            searchDataLoading(false);
                            loadError(true)
                        });
                    }
                    return searchData().result;
                }
            }
        }
    }
};

export const cache = new InMemoryCache(cacheConfig);
