import React from "react";
import { gql, useQuery } from "@apollo/client";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { t } from "@lingui/macro";
import EpisodeListItem from "../../components/EpisodeListItem";
import ReactPaginate from 'react-paginate';
import {Episode, EpisodesResponse, Podcast} from "../../types/api";

import './EpisodeListTab.css';
import Head from "../../utils/Head";
import {currentUrl, host} from "../../utils/cache";


const GET_EPISODES = gql`
    query GetEpisodes($slug: String!, $offset: Int!, $count: Int!) {
        episodes(slug: $slug, offset: $offset, count: $count) {
            count
            episodes {
                slug,
                name,
                trackUrl,
                duration,
                summary,
                link,
                published,
                image
            }
        }
    }
`;

interface EpisodesResponseProps {
    episodes: EpisodesResponse
}


function EpisodeListTab() {
    const podcast = useOutletContext<Podcast>();
    const itemsCount = 10;
    const location = useLocation();
    const navigate = useNavigate();
    const query = new URLSearchParams(location.search);
    const currentPage = parseInt(query.get("page") || "1");
    const {loading, data} = useQuery<EpisodesResponseProps>(GET_EPISODES, {
        variables: {
            slug: podcast.slug,
            offset: (currentPage - 1) * itemsCount,
            count: itemsCount
        }
    });

    let episodes: Array<Episode> = [];
    let count = 0;

    if (data && data.episodes) {
        episodes = data.episodes.episodes || [];
        count = data.episodes.count;
    }

    const pageCount = Math.ceil(count / itemsCount);
    const handlePageClick = (event: {selected: number}) => {
        query.set("page", (event.selected + 1).toString());
        // history.replace({ pathname: location.pathname, search: query.toString() });
        navigate(`?${query.toString()}`);
    }

    if (loading) return <div className="page-loading"><img src="/img/rings.svg" alt=""/></div>;
    currentUrl(`${podcast.id}/e${location.search}`);
    const ogHost = host() !== 'podcast.kz' ? `og.${host()}` : 'og.pc.st';
    return (
        <div className="container">
            <Head
                title={`${podcast.title} – ${t`Эпизоды`}`}
                description={podcast.description}
                image={`https://${ogHost}/${podcast.ogImage}`}
                url={currentUrl()}
                backgroundColor={podcast.backgroundColor}
                backgroundColorDark={podcast.backgroundColor}
            />
            <div className="episode-wrapper">
                {episodes.map(e => (
                    <EpisodeListItem
                        key={e.slug}
                        slug={e.slug}
                        trackUrl={e.trackUrl}
                        duration={e.duration}
                        description={e.summary}
                        title={e.name}
                        date={e.published}
                        href={`/e/${e.slug.slice(-1) === '.' ? e.slug + '/' : e.slug}`}
                        author={podcast.author}
                        album={podcast.title}
                        artwork={e.image || podcast.artwork}
                    />
                ))}
            </div>
            <div className="pagination-wrapper">
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    forcePage={currentPage - 1}
                    previousLabel="<"
                    activeClassName="active"
                    containerClassName="pagination"
                    marginPagesDisplayed={1}/>
            </div>
        </div>
    );
}

export default EpisodeListTab;
