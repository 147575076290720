import {useEffect, useState} from "react";
import {gql, useQuery, useReactiveVar} from "@apollo/client";
import {Link, useParams} from "react-router-dom";
import {Trans} from "@lingui/macro";
import {Maybe} from "graphql/jsutils/Maybe";

import {Episode} from "../types/api";
import Loading from "./Loading";
import Search from "./Search";
import EpisodeLink from "../components/EpisodeLink";
import {androidUrl, currentUrl, iOSUrl, selectedLanguage} from "../utils/cache";
import {playerShareHref} from "../client";
import usePlayer from "../hooks/usePlayer";

import './EpisodePage.css';
import moment from "moment";
import {autolinker, convert_time, decode_time, hostTitle} from "../utils";
import {statusCode, host} from "../utils/cache";
import Layout from "../layouts/Layout";
import Head from "../utils/Head";
import ReactHtmlParser from "react-html-parser";

import {ResourceLink} from "../components/ResourceLink";
import SettingsModal from "../components/SettingsModal/SetingsModal";

const Arrow = () => (<svg width="26" height="15" viewBox="0 0 26 15" fill="none">
  <path opacity="0.3"
        d="M0.999999 1.981L12.6097 12.9317C12.6587 12.9777 12.7188 13.0146 12.7861 13.0398C12.8533 13.0651 12.9262 13.0781 13 13.0781C13.0738 13.0781 13.1467 13.0651 13.2139 13.0398C13.2812 13.0146 13.3413 12.9777 13.3903 12.9317L25 1.98099"
        className="arrow-stroke" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>);

const GET_EPISODE = gql`
  query GetEpisode($slug: String!) {
    episode(slug: $slug) {
      slug
      name
      description
      link
      trackUrl
      duration
      published
      image
      summary
      ogImage
      
      podcast {
        id
        slug
        title
        backgroundColor
        artwork
        textColor
        links {
          href
          title
          img
        }
      }
   
      linkBreaker
      linkCastbox
      linkStitcher
      linkSoundstream
      linkSpotify
      linkCastro
      linkItunes
      linkOvercast
      linkIheartradio
      linkPocketcasts
      linkPlayerfm
      linkVk
      linkRadiopublic
      linkYandex
      linkSberWeb
      linkSberMobile
      linkYoutube
    }
  }
`;


interface QueryEpisodeTypes {
  episode: Maybe<Episode>;
}

const TIMINGS_REGEX = /((\d?\d):)?(\d?\d):(\d\d)/gm;


function EpisodePage() {
  const {slug} = useParams<{ slug: string }>();
  const {loading, data, error} = useQuery<QueryEpisodeTypes>(GET_EPISODE, {
    variables: {slug: slug}
  });
  const {isReady, isPlaying, togglePlayPause, seek, updateTrack, source} = usePlayer();
  const language = useReactiveVar(selectedLanguage);
  const showRussian = language !== "uk";

  useEffect(() => {
    if (!isReady && data && data.episode) {
      updateTrack(
        data.episode.trackUrl,
        data.episode.name,
        data.episode.duration,
        false,
        0,
        data.episode.podcast.author,
        data.episode.podcast.title,
        data.episode.podcast.artwork,
        host().toUpperCase()
      );
      playerShareHref(`https://${host()}/e/${data.episode.slug}`);
    }
    // eslint-disable-next-line
  }, [data]);

  const [openModal, setOpenModal] = useState(false);

  // @ts-ignore
  const handleOpen = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setOpenModal(true);
  }
  const handleClose = () => setOpenModal(false);


  if (loading) return <Layout>
    <div>
      <div
        className="header podcast-header episode-header"
        // style={{ paddingBottom: 0, background: "linear-gradient(180deg, #613384 0%, #3f2156 100%)" }}
      >
        <div className="container"><Loading/></div>
      </div>
    </div>
  </Layout>;
  if (error) {
    statusCode(500);
    return <Search error500={true}/>;
  }
  if (!data || !data.episode) {
    statusCode(404);
    return <Search error404={true}/>;
  }

  statusCode(200);

  const episode = data.episode;
  currentUrl(`e/${episode.slug}`);

  iOSUrl(episode.linkItunes);
  androidUrl(episode.linkGoogle || "");

  const links = episode.podcast.links as any[];
  const isThisPlaying = source && source === episode.trackUrl && isPlaying;

  const replaced = episode.description.replace(TIMINGS_REGEX, "<a href='#$&'>$&</a>");
  const linked = autolinker.link(replaced);
  const url = `e/${episode.slug.slice(-1) === '.' ? episode.slug + '/' : episode.slug}`;
  let description;
  try {
    const component = ReactHtmlParser(linked, {
      transform: (node) => {
        if (node.name === 'a' && node.attribs && node.attribs.href) {
          const matched = node.attribs.href.match(TIMINGS_REGEX);
          if (matched && matched[0]) {
            return <button className="btn-link" onClick={(e) => {
              e.preventDefault();
              if (isThisPlaying) {
                seek(decode_time(matched[0]));
              } else {
                updateTrack(
                  episode.trackUrl,
                  episode.name,
                  episode.duration,
                  true,
                  decode_time(matched[0]),
                  episode.podcast.author,
                  episode.podcast.title,
                  episode.podcast.artwork,
                  host().toUpperCase()
                );
                playerShareHref(`https://${host()}/${url}`);
                togglePlayPause();
              }
            }} key={matched[0]}>{node.children![0].data}</button>
          }
        }
      }
    });
    description = <div className="episode-description">{component}</div>;
  } catch (e) {
    console.error(e);
    description = <div className="episode-description" dangerouslySetInnerHTML={{__html: episode.description}}/>
  }

  const handlePlay = () => {
    if (isThisPlaying) {
      togglePlayPause();
    } else {
      updateTrack(episode.trackUrl, episode.name, episode.duration, true);
      playerShareHref(`https://${host()}/${url}`);
      togglePlayPause();
    }
  }

  const backgroundColor =
    `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%), ${episode.podcast.backgroundColor}` ||
    "linear-gradient(180deg, #613384 0%, #3f2156 100%)";

  let logoUrl = (episode.podcast.textColor === "#ffffff" ? "/img/pcst-logo-white.svg" : "/img/pcst-logo-black.svg");
  switch (host().toLowerCase()) {
    case "podcast.ru":
      logoUrl = (episode.podcast.textColor === "#ffffff" ? "/img/podcast-ru-white.svg" : "/img/podcast-ru-black.svg");
      break;
    case "podcast.kz":
      logoUrl = (episode.podcast.textColor === "#ffffff" ? "/img/podcast-kz-white.svg" : "/img/podcast-kz-black.svg");
      break;
  }

  const ogHost = host() !== 'podcast.kz' ? `og.${host()}` : 'og.pc.st';

  return (<Layout>
    <Head
      title={`${episode.name} – ${episode.podcast.title}`}
      description={episode.summary}
      image={`https://${ogHost}/e/${episode.slug}/`}
      url={currentUrl()}
      backgroundColor={episode.podcast.backgroundColor!}
      backgroundColorDark={episode.podcast.backgroundColor!}
    />
    <div>
      <div
        className="header podcast-header episode-header"
        style={{paddingBottom: 0, background: backgroundColor}}
      >
        <div className="container">
          <Link to={`/${episode.podcast.slug}/e/`} style={{zIndex: 100}} className="img-btn">
            <Arrow />
          </Link>

          <Link to="/" title={hostTitle()}>
            <img
              src={logoUrl}
              alt="logo"
              className="podcast-logo"
              title={hostTitle()}
            />
          </Link>
          <div className="episode-header-content">
            <div className="podcast-info-wrapper">
              <div className="podcast-img-wrapper">
                <img
                  src={episode.image || episode.podcast.artwork}
                  className="podcast-img"
                  alt="podcast"
                  title={episode.name}
                />
              </div>
              <div className="episode-header-description-wrapper">
                <div className="episode-header-description">
                  <div className="episode-podcast-link">
                    <Link to={`/${episode.podcast.slug}`} title={episode.podcast.title}>{episode.podcast.title}</Link>
                  </div>
                  <h1>{episode.name}</h1>
                </div>
                <div className="play-wrapper">
                  <button className="play-episode img-btn" onClick={handlePlay}>
                    <img src={isThisPlaying ? "/img/pause.svg" : "/img/play.svg"} alt="play" width={40} height={40}/>
                    <span><Trans>Слушать эпизод</Trans> • {convert_time(episode.duration)}</span>
                  </button>
                  <div className="episode-date">{moment.utc(episode.published * 1000).format("DD.MM.YYYY")}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container episode-page">
        <div className="spacer"></div>
        <div className="services-list">
          <div><Trans>Слушайте эпизод на любимых платформах:</Trans></div>
          <ul>
            <EpisodeLink title="Apple Podcasts" href={episode.linkItunes} img="/img/logos/apple-podcasts.svg"/>
            {showRussian ?
              <EpisodeLink title="Yandex Music" href={episode.linkYandex} img="/img/logos/yandex-music.svg"/> : <></>}
            <EpisodeLink title="Youtube" href={episode.linkYoutube} img="/img/logos/youtube.svg"/>
            <EpisodeLink title="Spotify" href={episode.linkSpotify} img="/img/logos/spotify.png"/>
            {showRussian ? <EpisodeLink title="Звук" href={episode.linkSberWeb} img="/img/logos/sber.svg"/> : <></>}
            <EpisodeLink title="Castbox" href={episode.linkCastbox} img="/img/logos/castbox.png"/>
            <EpisodeLink title="Pocket Casts" href={episode.linkPocketcasts} img="/img/logos/pocket-casts.svg"/>
            <EpisodeLink title="Stitcher" href={episode.linkStitcher} img="/img/logos/stitcher.png"/>
            <EpisodeLink title="iHeart" href={episode.linkIheartradio} img="/img/logos/iheartradio.png"/>
            <EpisodeLink title="PlayerFM" href={episode.linkPlayerfm} img="/img/logos/playerfm.png"/>
            {showRussian ? <EpisodeLink title="ВКонтакте" href={episode.linkVk} img="/img/logos/vk.svg"/> : <></>}
            {showRussian ? <EpisodeLink title="SoundStream" href={episode.linkSoundstream}
                                        img="/img/logos/soundstream.png"/> : <></>}
            <EpisodeLink title="Overcast" href={episode.linkOvercast} img="/img/logos/overcast.png"/>
            {/*<EpisodeLink title="Breaker" href={episode.linkBreaker} img="/img/logos/breaker.svg" />*/}
            <EpisodeLink title="Castro" href={episode.linkCastro} img="/img/logos/castro.svg"/>
            <EpisodeLink title="RadioPublic" href={episode.linkRadiopublic} img="/img/logos/radiopublic.svg"/>
          </ul>
        </div>
        {description}
        <div className="resources">
          {links.map(l => (
            <ResourceLink {...l} key={l.href} url={url}/>
          ))}
          <div className="resource-link">
            <div className="resource link-content">
              <img src="/img/embed-icon-white.svg" alt="Embed Podcast"/>
              <div className="resource-info">
                <span><Trans>Добавить подкаст на сайт</Trans></span>
                <a href="#" title="Embed Podcast" onClick={handleOpen}>Embed Podcast</a>
              </div>
            </div>
          </div>
        </div>
        <SettingsModal
          open={openModal}
          handleClose={handleClose}
          url={url}
          color={episode.podcast.backgroundColor!}
          artwork={episode.image || episode.podcast.artwork}
          textColor={episode.podcast.textColor!}
        />
      </div>
    </div>
  </Layout>);
}

export default EpisodePage;
