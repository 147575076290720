import React from "react";
import { PodcastLinkProps } from "./interfaces";

export function PodcastLinkMobile({
  href,
  img,
  title,
  beta = false
}: PodcastLinkProps) {
  if (!href) return null;

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a className="link" href={href} title={title} target="_blank">
      <div className="img">
        <img src={img} alt={title} />
      </div>
      {title}
      {beta ? (
        <sup style={{ opacity: 0.5 }}>
          &nbsp;<small>beta</small>
        </sup>
      ) : null}
      <div className="arr">
        <svg width="20px">
          <use xlinkHref="/img/sprite.svg#thin-arrow" />
        </svg>
      </div>
    </a>
  );
}
