import React from "react";
import {hostTitle} from "../utils";
import {host} from "../utils/cache";

import {PcstLogo, PodcastRuLogo, PodcastKzLogo} from "../components/Logos";
import Rings from "../components/Rings";

function Loading() {
  const title = hostTitle();

  let logoUrl = <PcstLogo />;
  switch (host()) {
    case "podcast.ru":
      logoUrl = <PodcastRuLogo />;
      break;
    case "podcast.kz":
      logoUrl = <PodcastKzLogo />;
      break;
  }
  return <div className="content">
    <div className="container">
      <div className="big-logo"><a href="/" title={title}>{logoUrl}</a></div>
      <div className="page-loading"><Rings height={234}/></div>
    </div>
  </div>;
}

export default Loading;
