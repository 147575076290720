import {playerShareHref} from "../../client";
import usePlayer from "../../hooks/usePlayer";
import useAudioTime from "../../hooks/useAudioTime";

import styles from './ActionButtons.module.css'

const Loop15 = () => (<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g opacity="0.7" clipPath="url(#clip0_2842_85)">
    <path
      d="M0.999998 22.0003L0.999999 25.0029C0.999999 26.5942 1.63214 28.1204 2.75736 29.2456C3.88258 30.3708 5.4087 31.0029 7 31.0029L25 31.0029C26.5913 31.0029 28.1174 30.3708 29.2426 29.2456C30.3679 28.1204 31 26.5942 31 25.0029L31 13.0029C31 11.4116 30.3679 9.8855 29.2426 8.76029C28.1174 7.63507 26.5913 7.00293 25 7.00293L10 7.00293"
      className={styles.strokeColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M12.088 25.4264C12.5733 25.4264 12.9653 25.0157 12.9653 24.5304V13.8157C12.9653 13.2557 12.5173 12.7891 11.9387 12.7891C11.6587 12.7891 11.3787 12.9011 11.2107 13.1064L8.69067 15.6824C8.56001 15.8131 8.46667 15.9997 8.46667 16.2051C8.46667 16.6157 8.80267 16.9704 9.23201 16.9704C9.43734 16.9704 9.62401 16.8771 9.77334 16.7277L11.2107 15.1971V24.5304C11.2107 25.0157 11.6027 25.4264 12.088 25.4264Z"
      className={styles.fillColor}/>
    <path
      d="M15.5597 24.0824C16.3624 25.0157 17.389 25.5571 18.9384 25.5571C21.253 25.5571 22.821 23.8211 22.821 21.4317C22.821 18.8371 21.365 17.4184 19.5544 17.4184C18.565 17.4184 17.725 17.8851 17.2584 18.4824V14.4504H21.4584C21.869 14.4504 22.2424 14.0957 22.2424 13.6664C22.2424 13.2371 21.869 12.8824 21.4584 12.8824H16.437C15.821 12.8824 15.485 13.2184 15.485 13.8344V19.0797C15.485 19.5837 15.9144 20.0317 16.437 20.0317C16.717 20.0317 16.9597 19.9197 17.1277 19.7517C17.6877 19.1731 18.2664 18.9677 18.9757 18.9677C20.2264 18.9677 21.0104 19.8637 21.0104 21.4877C21.0104 22.8131 20.2077 23.9704 18.8824 23.9704C17.9677 23.9704 17.277 23.6344 16.6984 22.9997C16.5677 22.8504 16.3624 22.7384 16.1197 22.7384C15.6904 22.7384 15.3357 23.1117 15.3357 23.5411C15.3357 23.7464 15.429 23.9517 15.5597 24.0824Z"
      className={styles.fillColor}/>
    <path d="M10 7.00293L16 1.00293" className={styles.strokeColor} strokeWidth="1.5" strokeLinecap="round"
          strokeLinejoin="round"/>
    <path d="M4 7.00293L10 1.00293" className={styles.strokeColor} strokeWidth="1.5" strokeLinecap="round"
          strokeLinejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_2842_85">
      <rect width="32" height="32" fill="white" transform="translate(32 32) rotate(180)"/>
    </clipPath>
  </defs>
</svg>);

const Loop30 = () => (<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.7" clipPath="url(#clip0_2842_90)">
      <path
        d="M31 22.0003L31 25.0029C31 26.5942 30.3679 28.1204 29.2426 29.2456C28.1174 30.3708 26.5913 31.0029 25 31.0029L7 31.0029C5.4087 31.0029 3.88258 30.3708 2.75736 29.2456C1.63214 28.1204 1 26.5942 1 25.0029L1 13.0029C1 11.4116 1.63214 9.8855 2.75736 8.76029C3.88258 7.63507 5.4087 7.00293 7 7.00293L22 7.00293"
        className={styles.strokeColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M22 7.00293L16 1.00293" className={styles.strokeColor} strokeWidth="1.5" strokeLinecap="round"
            strokeLinejoin="round"/>
      <path d="M28 7.00293L22 1.00293" className={styles.strokeColor} strokeWidth="1.5" strokeLinecap="round"
            strokeLinejoin="round"/>
      <path
        d="M10.848 25.5576C13.2933 25.5576 14.6933 24.0456 14.6933 21.955C14.6933 20.2003 13.424 19.0803 12.2853 18.8936C13.4613 18.6323 14.544 17.531 14.544 16.019C14.544 13.8163 12.9947 12.6963 10.848 12.6963C9.504 12.6963 8.36533 13.2376 7.544 14.1523C7.432 14.283 7.33866 14.4696 7.33866 14.6563C7.33866 15.0856 7.67466 15.4216 8.08533 15.4216C8.30933 15.4216 8.496 15.3283 8.62666 15.1976C9.20533 14.619 9.87733 14.283 10.7173 14.283C11.912 14.283 12.7707 14.9363 12.7707 16.2803C12.7707 17.5496 11.856 18.1843 10.6613 18.1843H10.2693C9.85866 18.1843 9.52266 18.539 9.52266 18.9683C9.52266 19.3976 9.896 19.7523 10.3067 19.7523H10.68C11.9307 19.7523 12.9387 20.275 12.9387 21.8056C12.9387 23.1123 12.2293 23.971 10.7733 23.971C9.87733 23.971 9.13066 23.579 8.53333 22.963C8.40266 22.8136 8.19733 22.7203 7.97333 22.7203C7.544 22.7203 7.18933 23.075 7.18933 23.523C7.18933 23.7283 7.28266 23.9336 7.41333 24.0643C8.17866 24.9416 9.35466 25.5576 10.848 25.5576Z"
        className={styles.fillColor}/>
      <path
        d="M16.3287 19.1176C16.3287 22.1603 17.4114 25.5576 20.3794 25.5576C23.3474 25.5576 24.4114 22.1603 24.4114 19.1176C24.4114 16.0563 23.3287 12.6963 20.3794 12.6963C17.4114 12.6963 16.3287 16.0563 16.3287 19.1176ZM22.6007 19.1176C22.6007 21.5443 22.1527 23.971 20.3794 23.971C18.5874 23.971 18.1394 21.5443 18.1394 19.1176C18.1394 16.6723 18.5874 14.283 20.3794 14.283C22.1527 14.283 22.6007 16.6723 22.6007 19.1176Z"
        className={styles.fillColor}/>
    </g>
    <defs>
      <clipPath id="clip0_2842_90">
        <rect width="32" height="32" fill="white" transform="matrix(1 8.74228e-08 8.74228e-08 -1 0 32)"/>
      </clipPath>
    </defs>
  </svg>
);

const Share = () => (<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.7">
      <path d="M16 18.3317V3.66504" className={styles.strokeColor} strokeWidth="1.5" strokeLinecap="round"
            strokeLinejoin="round"/>
      <path d="M10.6667 8.33333L16.0001 3L21.3334 8.33333" className={styles.strokeColor} strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"/>
      <path
        d="M20.125 13H25.625C25.9897 13 26.3394 13.1355 26.5973 13.3766C26.8551 13.6177 27 13.9447 27 14.2857V29.7143C27 30.0553 26.8551 30.3823 26.5973 30.6234C26.3394 30.8645 25.9897 31 25.625 31H6.375C6.01033 31 5.66059 30.8645 5.40273 30.6234C5.14487 30.3823 5 30.0553 5 29.7143V14.2857C5 13.9447 5.14487 13.6177 5.40273 13.3766C5.66059 13.1355 6.01033 13 6.375 13H11.875"
        className={styles.strokeColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
  </svg>
);

const Airplay = () => (<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g opacity="0.7" clipPath="url(#clip0_2842_78)">
    <path
      d="M16.5334 20.0001C16.2667 19.6445 15.7334 19.6445 15.3778 20.0001L5.9556 30.9334C5.60004 31.3778 5.86671 32.0001 6.40004 32.0001H25.5111C26.0445 32.0001 26.3111 31.3778 25.9556 30.9334L16.5334 20.0001Z"
      className={styles.fillColor}/>
    <path
      d="M16 0C7.2 0 0 7.2 0 16C0 20.8889 2.22222 25.3333 5.68889 28.2667C5.68889 28.2667 5.68889 28.2667 5.77778 28.3556C5.86667 28.4444 5.95556 28.4444 6.04444 28.3556C6.04444 28.3556 6.04444 28.2667 6.13333 28.2667C6.48889 27.8222 6.75556 27.5556 6.93333 27.2889C6.93333 27.2889 6.93333 27.2889 7.02222 27.2C7.11111 27.1111 7.11111 27.0222 7.02222 26.9333L6.93333 26.8444C3.73333 24.2667 1.77778 20.3556 1.77778 16C1.77778 8.17778 8.17778 1.77778 16 1.77778C23.8222 1.77778 30.2222 8.17778 30.2222 16C30.2222 20.4444 28.1778 24.3556 25.0667 26.9333C25.0667 26.9333 25.0667 26.9333 24.9778 26.9333C24.8889 27.0222 24.8889 27.0222 24.9778 27.1111C25.1556 27.3778 25.5111 27.7333 25.8667 28.1778C25.9556 28.2667 25.9556 28.2667 26.0444 28.1778C29.6 25.2444 31.8222 20.8 31.8222 15.8222C32 7.2 24.8 0 16 0Z"
      className={styles.fillColor}/>
    <path
      d="M20.4445 21.5998C20.5334 21.5109 20.6222 21.422 20.7111 21.3331C22.2222 19.9998 23.1111 18.1331 23.1111 15.9998C23.1111 12.0887 19.9111 8.88867 16 8.88867C12.0889 8.88867 8.88892 12.0887 8.88892 15.9998C8.88892 18.1331 9.86669 19.9998 11.2889 21.3331C11.3778 21.422 11.4667 21.5109 11.5556 21.5998C11.6445 21.6887 11.7334 21.6887 11.8222 21.5998C11.9111 21.5109 11.9111 21.422 12 21.422C12.3556 21.0664 12.6222 20.7109 12.8 20.5331C12.8889 20.4442 12.8889 20.3553 12.8 20.3553C11.4667 19.2887 10.6667 17.6887 10.6667 15.9998C10.6667 13.0664 13.0667 10.6664 16 10.6664C18.9334 10.6664 21.3334 13.0664 21.3334 15.9998C21.3334 17.6887 20.5334 19.2887 19.2889 20.1776C19.2 20.2664 19.2 20.3553 19.2889 20.3553C19.6445 20.7109 19.9111 21.0664 20.0889 21.2442C20.0889 21.3331 20.1778 21.3331 20.2667 21.422C20.2667 21.5998 20.3556 21.5998 20.4445 21.5998Z"
      className={styles.fillColor}/>
    <path
      d="M23.2889 24.9777L23.3778 24.8888C25.9556 22.7554 27.5556 19.5554 27.5556 15.9999C27.5556 9.59989 22.4 4.44434 16 4.44434C9.60001 4.44434 4.44446 9.59989 4.44446 15.9999C4.44446 19.5554 6.04446 22.7554 8.62224 24.8888C8.62224 24.8888 8.62224 24.8888 8.71113 24.9777C8.80001 25.0666 8.8889 25.0666 8.97779 24.9777C9.33335 24.6221 9.60001 24.2666 9.77779 24.0888C9.77779 24.0888 9.86668 23.9999 9.95557 23.911C10.0445 23.8221 9.95557 23.7332 9.95557 23.7332L9.86668 23.6443C7.73335 21.8666 6.31112 19.111 6.31112 16.0888C6.31112 10.6666 10.6667 6.311 16.0889 6.311C21.5111 6.311 25.8667 10.6666 25.8667 16.0888C25.8667 19.111 24.5333 21.7777 22.3111 23.5554L22.2222 23.6443C22.1333 23.7332 22.1333 23.7332 22.2222 23.8221C22.3111 23.911 22.3111 23.911 22.3111 23.9999C22.4889 24.1777 22.7556 24.5332 23.1111 24.8888C23.1111 24.8888 23.1111 24.9777 23.2 24.9777C23.1111 24.9777 23.2 24.9777 23.2889 24.9777Z"
      className={styles.fillColor}/>
  </g>
  <defs>
    <clipPath id="clip0_2842_78">
      <rect width="32" height="32" fill="white"/>
    </clipPath>
  </defs>
</svg>);

export default function ActionButtons() {
  const {isReady, duration, seek, isAirplayAvailable, showPlaybackTargetPicker} = usePlayer();
  const position = useAudioTime();

  const handleMinus = () => {
    const new_position = position - 15;
    seek(new_position < 0 ? 0 : new_position);
  };
  const handlePlus = () => {
    const new_position = position + 30;
    if (new_position < duration) {
      seek(new_position);
    } else {
      seek(duration);
    }
  };
  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        url: playerShareHref() || ""
      });
    }
  };
  const handleAirplay = () => {
    showPlaybackTargetPicker();
  };
  return <div className="action-bar">
    <div className="action">
      <button className="action-btn" onClick={handleMinus} hidden={!isReady}>
        <Loop15/>
      </button>
      <button className="action-btn" onClick={handlePlus} hidden={!isReady}>
        <Loop30/>
      </button>
    </div>
    <div className="action">
      <button className="action-btn" hidden={!navigator.share} onClick={handleShare}>
        <Share/>{" "}
      </button>
      <button className="action-btn" onClick={handleAirplay} hidden={!isAirplayAvailable || !isReady}>
        <Airplay />
      </button>
    </div>
  </div>;
}
