import React from "react";

interface EpisodeLinkProps {
    title: string;
    href?: string | null;
    img: string;
}

export default function EpisodeLink({title, href, img}: EpisodeLinkProps) {
    if (!href) return <></>;
    return <li>
        <a
            href={href}
            title={title}
            target="_blank"
            rel="noreferrer"
        >
            <img src={img} alt={title} />
        </a>
    </li>;
}
