import React from "react";
import { SelectLocaleButton } from "./SelectLocaleButton";
import "./SelectLocale.css";

interface SelectLocaleProps {
  onClick?(): void;
}

export const SelectLocaleButtonActive: React.FC<SelectLocaleProps> = ({
  onClick,
  children
}) => {
  return (
    <SelectLocaleButton className="language active-language" onClick={onClick}>
      {children}
    </SelectLocaleButton>
  );
};
