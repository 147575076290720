import { useEffect, useState } from "react";

export function useIsMobileDeviceFlag() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 664);
  const updateContainerSize = () => {
    setIsMobile(window.innerWidth < 664);
  };

  useEffect(() => {
    window.addEventListener("resize", updateContainerSize);
  }, []);

  return isMobile;
}
