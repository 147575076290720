import Autolinker from "autolinker";
import { host } from "./cache";

export function convert_time(seconds: number): string {
    return new Date(seconds * 1000).toISOString().substr(11, 8);
}

export const autolinker = new Autolinker( {
    urls : {
        schemeMatches : true,
        wwwMatches    : true,
        tldMatches    : true
    },
    email       : true,
    phone       : true,
    mention     : false,
    hashtag     : false,

    stripPrefix : true,
    stripTrailingSlash : false,
    newWindow   : true,

    truncate : {
        length   : 0,
        location : 'end'
    },

    className : ''
} );

export function decode_time(time: string) {
    const times = time.split(':');
    let seconds = 0;
    let stamp;
    let k = 1;
    while ((stamp = times.pop())) {
        seconds += parseInt(stamp) * k;
        k = k * 60;
    }
    return seconds;
}

function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const hostTitle = () => {
    const h = host().toLowerCase();
    return h === "pc.st" ? h.toUpperCase() : capitalizeFirstLetter(h);
}
