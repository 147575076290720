import React from "react";
import {PodcastLink, PodcastLinkMobile} from "../../components/PodcastLink";
import {useReactiveVar} from "@apollo/client";
import {currentUrl, host, selectedLanguage} from "../../utils/cache";
import {useOutletContext} from "react-router-dom";
import {Podcast} from "../../types/api";
import Head from "../../utils/Head";
import ForcePodcastUpdate from "../../components/ForcePodcastUpdate";


function InfoTab() {
    const podcast = useOutletContext<Podcast>();
    const language = useReactiveVar(selectedLanguage);
    const showRussian = language !== "uk";

    const links = [];
    links.push({
        href: podcast.linkItunes,
        title: "Apple Podcasts",
        img: "/img/logos/apple-podcasts.svg"
    });
    if (showRussian) links.push({
        href: podcast.linkYandex,
        title: "Yandex Music",
        img: "/img/logos/yandex-music.svg"
    })
    links.push({
        href: podcast.linkYoutube,
        title: "Youtube Music",
        img: "/img/logos/youtube.svg"
    });
    links.push({
        href: podcast.linkSpotify,
        title: "Spotify",
        img: "/img/logos/spotify.png"
    });
    if (showRussian) links.push({
        href: podcast.linkSberWeb,
        title: "Звук",
        img: "/img/logos/sber.svg"
    });
    links.push({
        href: podcast.linkCastbox,
        title: "Castbox",
        img: "/img/logos/castbox.png"
    });
    links.push({
        href: podcast.linkPocketcasts,
        title: "Pocket Casts",
        img: "/img/logos/pocket-casts.svg"
    });
    links.push({
        href: podcast.linkStitcher,
        title: "Stitcher",
        img: "/img/logos/stitcher.png"
    });
    links.push({
        href: podcast.linkIheartradio,
        title: "iHeart",
        img: "/img/logos/iheartradio.png"
    });
    links.push({
        href: podcast.linkPlayerfm,
        title: "PlayerFM",
        img: "/img/logos/playerfm.png"
    });
    if (showRussian) links.push({
        href: podcast.linkVk,
        title: "ВКонтакте",
        img: "/img/logos/vk.svg"
    });
    if (showRussian) links.push({
        href: podcast.linkSoundstream,
        title: "SoundStream",
        img: "/img/logos/soundstream.png"
    });
    links.push({
        href: podcast.linkOvercast,
        title: "Overcast",
        img: "/img/logos/overcast.png"
    });
    // {
    //   href: podcast.linkBreaker,
    //   title: "Breaker",
    //   img: "/img/logos/breaker.svg"
    // },
    links.push({
        href: podcast.linkCastro,
        title: "Castro",
        img: "/img/logos/castro.svg"
    });
    links.push({
        href: podcast.linkRadiopublic,
        title: "RadioPublic",
        img: "/img/logos/radiopublic.png"
    });
    links.push({
        href: podcast.linkRss,
        title: "RSS feed",
        img: "/img/logos/rss.png"
    });

    currentUrl(`${podcast.id}`);

    const ogHost = host() !== 'podcast.kz' ? `og.${host()}` : 'og.pc.st';

    return (
        <div className="container">
            <Head
                title={podcast.title}
                description={podcast.description}
                image={`https://${ogHost}/${podcast.slug}`}
                url={currentUrl()}
                backgroundColor={podcast.backgroundColor}
                backgroundColorDark={podcast.backgroundColor}
            />
            <div className="podcast-links">
                {links.map(({href, title, beta = false, img}, index) => (
                    <PodcastLink key={index} href={href} img={img} beta={beta} title={title}/>
                ))}
            </div>

            <div className="item-links">
                {links.map(({href, title, beta = false, img}, index) => (
                    <PodcastLinkMobile key={index} href={href} img={img} beta={beta} title={title}/>
                ))}
            </div>
            <ForcePodcastUpdate podcastId={podcast.id} />
        </div>
    );
}

export default InfoTab;
