import {useEffect, useRef, useState} from "react";
import usePlayer from "./usePlayer";


export default function useAudioTime() {
    const frameRef = useRef<number>();
    const [pos, setPos] = useState(0);
    const { getPosition } = usePlayer();

    useEffect(() => {
        const animate = () => {
            setPos(getPosition())
            frameRef.current = requestAnimationFrame(animate)
        }

        frameRef.current = window.requestAnimationFrame(animate)

        return () => {
            if (frameRef.current) {
                cancelAnimationFrame(frameRef.current!)
            }
        }
    }, [getPosition]);

    return pos;
}
