import React, {useState} from "react";
import {useReactiveVar} from "@apollo/client";
import Footer from "../components/Footer";
import Player from "../components/Player";
import {SelectLocale} from "../components/SelectLocale";
import {i18n} from "@lingui/core";
import {currentUrl, host, selectedLanguage} from "../utils/cache";
import usePlayer from "../hooks/usePlayer";

import "../style.css";
import "../mobile.css";


export default function Layout ({ children }: {children: JSX.Element[] | JSX.Element}) {
    const [isOpenModal, setModalOpen] = useState(false);
    const [coordinates, setCoordinates] = useState({ top: 0, left: 0 });
    const { source } = usePlayer();
    const isPlayerOpen = !!source;
    const url = useReactiveVar(currentUrl);
    const currentLang = useReactiveVar(selectedLanguage);

    return <div className="flexible">
        <div className="flexible" style={{ filter: isOpenModal ? "blur(3px)" : "none" }}>
            {children}
            <Footer
                isPlayerOpen={isPlayerOpen}
                isOpenModal={isOpenModal}
                setModalOpen={setModalOpen}
                setCoordinates={setCoordinates}
            />
        </div>
        <Player />
        {isOpenModal && (
            <div
                style={{
                    position: "absolute",
                    top: `${coordinates.top}px`,
                    left: `${coordinates.left}px`
                }}
            >
                <SelectLocale
                    isOpen={isOpenModal}
                    setIsOpen={(lang: string) => {
                        if (host().toLowerCase() === 'pc.st') {
                            localStorage.setItem("selectedLanguage", lang);
                        }
                        if (lang === currentLang) {
                            return setModalOpen(false);
                        }
                        let urlHost = `pc.st/${lang}`;
                        // let redirectHost = 'pc.st';
                        switch (lang) {
                            case 'ru':
                                urlHost = 'podcast.ru';
                                break;
                            case 'kk':
                                urlHost = 'podcast.kz';
                                break;
                        }
                        const redirect = true; //host().toLowerCase() !== redirectHost;

                        if (redirect && typeof window !== "undefined" && typeof window.document !== "undefined") {
                            window.location.replace(`https://${urlHost}/${url}`);
                        } else {
                            i18n.activate(lang);
                            selectedLanguage(lang);
                        }
                        setModalOpen(!isOpenModal);
                    }}
                />
            </div>
        )}
    </div>;
}
