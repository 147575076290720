import { useIsMobileDeviceFlag } from "../../hooks";
import usePlayer from "../../hooks/usePlayer";

import TrackTitle from "./TrackTitle";
import TrackProgress from "./TrackProgress";
import TrackTime from "./TrackTime";
import ActionButtons from "./ActionButtons";

import "./Player.css";

const PlayButton = () => <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="36" cy="36" r="36" fill="#1E8BE4"/>
  <path
    d="M48.4149 38.2985L29.4352 47.7871C29.0433 47.9829 28.6078 48.0754 28.1701 48.0557C27.7324 48.0359 27.307 47.9047 26.9342 47.6744C26.5615 47.4441 26.2538 47.1224 26.0403 46.7398C25.8268 46.3572 25.7146 45.9263 25.7144 45.4882V26.5059C25.7144 26.0679 25.8263 25.6371 26.0394 25.2545C26.2526 24.8719 26.56 24.55 26.9325 24.3195C27.3049 24.089 27.7301 23.9574 28.1676 23.9373C28.6052 23.9172 29.0406 24.0091 29.4326 24.2045L48.4175 33.6853C48.8473 33.8981 49.2091 34.2267 49.462 34.6342C49.7148 35.0417 49.8486 35.5118 49.8484 35.9914C49.8481 36.471 49.7137 36.941 49.4604 37.3482C49.2071 37.7554 48.845 38.0837 48.4149 38.2959V38.2985Z"
    fill="white"/>
</svg>;

const PauseButton = () => <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="36" cy="36" r="36" fill="#1E8BE4"/>
  <path
    d="M29.684 48.1972C27.987 48.1972 26.5985 46.8087 26.5985 45.1117V26.5987C26.5985 24.9017 27.987 23.5132 29.684 23.5132C31.381 23.5132 32.7695 24.9017 32.7695 26.5987V45.1117C32.7695 46.8087 31.381 48.1972 29.684 48.1972ZM42.026 48.1972C40.329 48.1972 38.9405 46.8087 38.9405 45.1117V26.5987C38.9405 24.9017 40.329 23.5132 42.026 23.5132C43.723 23.5132 45.1115 24.9017 45.1115 26.5987V45.1117C45.1115 46.8087 43.723 48.1972 42.026 48.1972Z"
    fill="white"/>
</svg>;

export default function Player() {
  const {
    togglePlayPause, isReady, isPlaying, isLoading,
    source, title, duration
  } = usePlayer();
  const isMobile = useIsMobileDeviceFlag();
  const isPaused = !isPlaying;

  const handlePlay = () => {
    togglePlayPause();
  }

  if (!source) return <></>;

  const hideProgress = isMobile && !isLoading && !isReady;

  return <div className={`player`}>
    <div className="divide" />
    <button className="img-btn" onClick={handlePlay}>
      {isPaused ? <PlayButton /> : <PauseButton />}
    </button>
    <div className={isMobile ? "media-container-mobile" : "media-container"}>
      <TrackTitle title={title} buttons={!isMobile}/>
      {isMobile ? <ActionButtons /> : <></>}
      {!hideProgress ? <TrackProgress isLoading={isLoading} /> : <></>}
      <TrackTime trackDuration={duration} />
    </div>
  </div>
}
