import { t } from "@lingui/macro";

export const LINK_TITLES: {[Key: string]: string} = {};

Object.defineProperty(LINK_TITLES, 'UNIVERSAL_LINK', {
    get() {
        return t`Универсальная ссылка`;
    }
});

Object.defineProperty(LINK_TITLES, 'WEBSITE', {
    get() {
        return t`Официальный сайт`;
    }
});

Object.defineProperty(LINK_TITLES, 'AUTO_LINK', {
    get() {
        return t`Авто-ссылка`;
    }
});

Object.defineProperty(LINK_TITLES, 'SHORT_LINK', {
    get() {
        return t`Короткая ссылка`;
    }
});

Object.defineProperty(LINK_TITLES, 'SITE_TITLE', {
    get() {
        return t`Podcast.ru – умная ссылка для вашего подкаста`;
    }
});

Object.defineProperty(LINK_TITLES, 'AUTO_LINK_DESCRIPTION', {
    get() {
        return t`на смартфонах автоматически открывает встроенное приложение подкастов (Apple Podcasts или Google Podcasts)`;
    }
});
