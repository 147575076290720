import { ApolloClient, HttpLink, makeVar } from '@apollo/client';
import fetch from "cross-fetch";
import { cache } from "./utils/cache";

export const playerShareHref = makeVar<string | null>(null);

// declare global {
//   interface Window {
//     __APOLLO_STATE__: any;
//   }
// }

let client: any = null;

if (typeof window !== "undefined" && typeof window.document !== "undefined") {
  cache.restore(window.__APOLLO_STATE__);
  client = new ApolloClient({
    link: new HttpLink({uri: '/graphql', fetch}),
    cache: cache,
    connectToDevTools: true
  });
}

export default client;
