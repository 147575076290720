import React, {useState} from "react";
import { NavLink } from "react-router-dom";
import CopyToClipboard from 'react-copy-to-clipboard';
import {Trans} from "@lingui/macro";

import { host } from "../utils/cache";
import { SearchItemObj } from "../types/itunes";
import './SearchItem.css';

interface SearchItemProps {
  item: SearchItemObj;
}

function SearchItem(props: SearchItemProps) {
  const [copied, setCopied] = useState(false);
  const replaceHost = host();
  const fullUrl = `https://${replaceHost}/${props.item.itunes_id}`;

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  return <div className="list-el">
    <div className="card">
      <NavLink className="img" to={`/${props.item.itunes_id}`}><img src={props.item.artwork} width="256" height="256" alt={props.item.collectionName} /></NavLink>
      <div className="text">
        <div className="h2"><NavLink to={`/${props.item.itunes_id}`}>{props.item.title}</NavLink></div>
        <div className="table-simple">
          <table>
            <tbody>
              <tr>
                <td><NavLink to={`/${props.item.itunes_id}`}>{replaceHost}/{props.item.itunes_id}</NavLink></td>
                <td className="w0">
                  <CopyToClipboard text={fullUrl} onCopy={handleCopy}>
                    <div className={copied ? "wrap-copy copied":"wrap-copy"}>
                      <div className="link-copy">
                        <svg width="14" height="16">
                          <use xlinkHref="/img/sprite.svg#copy" />
                        </svg>
                      </div>
                      <div className="popup"><Trans>Ссылка скопирована</Trans></div>
                    </div>
                  </CopyToClipboard>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>;
}

export default SearchItem;
